import { ADD_ALARM_EVENTS, CLEAR_ALARM_EVENTS } from '../actions/types';
import { groupTimelineEvents } from '../helpers/groupEvents';

export default function recentEvents(state = { groupedEvents: [] }, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_ALARM_EVENTS:
      payload.events.sort((a, b) => b.time.localeCompare(a.time));
      return {
        ...payload,
        groupedEvents: groupTimelineEvents(payload.events, state.groupedEvents, 1, true),
      };
    case CLEAR_ALARM_EVENTS:
      return [];
    default:
      return state;
  }
}
