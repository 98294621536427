import axios from 'axios';

// eslint-disable-next-line import/prefer-default-export
export const fetchSensor = async (externalId, name, fromTime, toTime, capabilities) => {
  try {
    const startTime = fromTime / 1000;
    const endTime = toTime / 1000;
    const allCapabilities = capabilities.join(',');
    const response =
      await axios.get(`https://dev-api-trckr.zyax.se/devices/view/${externalId}/measurements?&fields=${allCapabilities}
      &time=${startTime}&time_to=${endTime}`);
    const { values } = response.data;
    const newData = {
      meta: {
        externalId,
        name,
        startTime,
        endTime,
        valid: true,
      },
      values,
    };
    return newData;
  } catch (error) {
    const newData = {
      meta: {
        name: `${error.response?.data?.message || 'Unknown error'} (${externalId})`,
        valid: false,
      },
      values: [],
    };
    return newData;
  }
};
