const prod = {
  apiUrl: 'https://2e6zzj3r8f.execute-api.eu-west-1.amazonaws.com/prod',
  socketUrl: 'wss://treko-wss-prod.zyax.se',
  publicVapidKey:
    'BJDhedTkmqxIVxyc-4j4H1ALuIZ0KyVM7p_-8JfCMWdaEsou2RdUWk_dvanf4n01MskxfYgNhAPAXOA9oN9AYdc',
  gtag: 'G-LHNEF09DCK',
};

const uat = {
  apiUrl: 'https://4olrm8en85.execute-api.eu-west-1.amazonaws.com/uat',
  socketUrl: 'wss://treko-wss-uat.zyax.se',
  publicVapidKey:
    'BGfdbJuYhJgKKFTHjqPmeZVYEtwa3Q1LD1E2t-p9a6Cg9JlX1GdMZtczy_zsZuYF6RBrrwrhMGqco6UwsfqW7RA',
  gtag: 'G-EZFFJSSBEW',
};

const dev = {
  apiUrl: 'https://ggc724z9lf.execute-api.eu-west-1.amazonaws.com/dev',
  socketUrl: 'wss://treko-wss-dev.zyax.se',
  publicVapidKey:
    'BKtA3VgvK5qOg5fQbcTyFadfa79d1aoRtvbcsitlfXKnXDLyWe97oyJVLIBhiR8aUQcp2pfmcxKVPGMtA7axxJ4',
  gtag: 'G-LY8FKG7EMS',
};

const local = {
  apiUrl: 'http://localhost:3001',
  socketUrl: 'wss://',
  publicVapidKey: '',
  gtag: 'G-LY8FKG7EMS',
};

let c;
switch (process.env.REACT_APP_STAGE) {
  case 'prod':
    c = prod;
    break;
  case 'uat':
    c = uat;
    break;
  case 'local':
    c = local;
    break;
  case 'dev':
  default:
    c = dev;
    break;
}
const config = c;

export default config;
