export const daysInWeek = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const abbrDay = (day) => day.charAt(0).toUpperCase() + day.slice(1, 3);

export const isSummerTime = () => {
  const now = new Date();
  const jan = new Date(now.getFullYear(), 0, 1);
  return jan.getTimezoneOffset() !== now.getTimezoneOffset();
};

export const calculateScheduleDstOffset = (scheduleDst) => {
  // Defaulting to true because schedules were created in dst
  const dst = typeof scheduleDst === 'boolean' ? scheduleDst : true;
  let dstAdjustment = 0;

  if (dst) {
    if (!isSummerTime()) dstAdjustment = 1;
  } else if (isSummerTime()) {
    dstAdjustment = -1;
  }
  return dstAdjustment;
};

export const separateSchedulePerDays = (schedules, tz = 0) => {
  const dayOfTime = (hr, inclusive = 0) => parseInt(hr / 24 + inclusive / 1e6, 10);
  const roundTime = (t) => Math.round(t * 100) / 100;

  const daysInWeekExpanded = [...daysInWeek, ...daysInWeek];

  const ws = {};
  daysInWeek.forEach((day) => {
    ws[day] = [];
  });

  schedules.forEach((schedule) => {
    let startDay = 0;
    let endDay = 1;
    let disarmTime = schedule.disarmTime + tz;
    let armTime = schedule.armTime + tz;

    if (disarmTime < 0) {
      disarmTime += 7 * 24;
      armTime += 7 * 24;
    }

    while (startDay !== endDay) {
      startDay = dayOfTime(disarmTime, 1);
      endDay = dayOfTime(armTime, -1);

      if (startDay === endDay) {
        armTime = roundTime(armTime % 24);
        if (armTime === 0) armTime = 24;
        ws[daysInWeekExpanded[startDay]].push({
          disarmTime: roundTime(disarmTime % 24),
          armTime,
        });
      } else {
        ws[daysInWeekExpanded[startDay]].push({
          disarmTime: roundTime(disarmTime % 24),
          armTime: 24,
        });

        disarmTime = 24 * (startDay + 1);
      }
    }
  });
  return ws;
};

export const groupScheduleArray = (scheduleArray) => {
  let [sa] = [scheduleArray];

  sa = sa.sort((a, b) => {
    if (a.disarmTime > b.disarmTime) return 1;
    if (a.disarmTime < b.disarmTime) return -1;
    return 0;
  });

  if (sa.length <= 1) return sa;

  let reducing = false;
  do {
    reducing = false;
    const end = sa.length - 1;
    let prev = sa[end];

    for (let i = end - 1; i >= 0; i -= 1) {
      const current = sa[i];

      if (current.armTime >= prev.disarmTime) {
        const disarmTime = Math.min(current.disarmTime, prev.disarmTime);
        const armTime = Math.max(current.armTime, prev.armTime);

        sa[i] = { disarmTime, armTime };
        sa.splice(i + 1, 1);

        reducing = true;
      }

      prev = sa[i];
    }
  } while (reducing);

  return sa;
};

export const createListOfSchedules = (ws, tz) => {
  const schedules = [];
  let tOffset = 0 - tz;
  daysInWeek.forEach((day) => {
    if (Array.isArray(ws[day])) {
      ws[day].forEach((el) => {
        let disarmTime = el.disarmTime + tOffset;
        let armTime = el.armTime + tOffset;

        if (armTime >= 7 * 24) {
          armTime -= 7 * 24;
          disarmTime -= 7 * 24;
        }

        if (armTime < 0) {
          armTime += 7 * 24;
          disarmTime += 7 * 24;
        }

        schedules.push({
          disarmTime,
          armTime,
        });
      });
    }

    tOffset += 24;
  });
  return schedules;
};

export const generateSchedule = (schedulesObj, tz) => {
  if (!Array.isArray(schedulesObj)) return {};
  const weeklySchedule = separateSchedulePerDays(schedulesObj, tz);
  return weeklySchedule;
};

const armingEvents = {
  ARMING: 0,
  DISARMING: 1,
};

export const combineSchedules = (currentSchedule, appendedSchedule) => {
  if (!currentSchedule?.length || currentSchedule.length < 1) return [];
  if (!appendedSchedule?.length || appendedSchedule.length < 1) return currentSchedule;

  const armingBlock = (sched) => sched.disarmTime > sched.armTime;

  if (!armingBlock(appendedSchedule[0]))
    return groupScheduleArray(currentSchedule.concat(appendedSchedule));

  const minSched = (a, b) => Math.min(a.armTime, a.disarmTime) - Math.min(b.armTime, b.disarmTime);
  let cs = currentSchedule.sort(minSched);
  const as = appendedSchedule.sort(minSched);

  const scheduleOverlaps = (a, b) =>
    Math.max(a.armTime, a.disarmTime) > Math.min(b.armTime, b.disarmTime) &&
    Math.min(a.armTime, a.disarmTime) < Math.max(b.armTime, b.disarmTime);

  const scheduleFirstWithinLatter = (a, b) =>
    a.armTime < b.armTime &&
    a.armTime > b.disarmTime &&
    a.disarmTime < b.armTime &&
    a.disarmTime > b.disarmTime;

  const subtractFirstFromLatter = (a, b) => {
    if (!armingBlock(a)) return [];
    if (armingBlock(b)) return [];

    if (!scheduleOverlaps(a, b)) return [b]; // Nothing to subtract

    if (scheduleFirstWithinLatter(a, b)) {
      return [
        { disarmTime: b.disarmTime, armTime: a.armTime },
        { disarmTime: a.disarmTime, armTime: b.armTime },
      ];
    }
    if (a.armTime > b.disarmTime)
      return [
        {
          disarmTime: Math.min(a.disarmTime, b.disarmTime),
          armTime: Math.min(a.armTime, b.armTime),
        },
      ];
    return [
      {
        disarmTime: Math.max(a.disarmTime, b.disarmTime),
        armTime: Math.max(a.armTime, b.armTime),
      },
    ];
  };

  let reducing;
  do {
    reducing = false;
    /**
     * I'm not happy with this while > for > for, but we are adding/manipulating items
     * as we go along so dont know what to do.. also, there is a very small dataset so
     * should be rather fast none the less.
     */
    for (let i = cs.length - 1; i >= 0; i -= 1) {
      for (let j = as.length - 1; j >= 0; j -= 1) {
        const a = cs[i];
        const b = as[j];
        if (scheduleOverlaps(a, b)) {
          cs.splice(i, 1);
          cs = cs.concat(subtractFirstFromLatter(b, a));

          reducing = true;
          i = -1;
          j = -1;
        }
      }
    }
  } while (reducing);

  const r = groupScheduleArray(cs);
  return r;
};

// To generate schedule from ScheduleForm component
export const generateNewSchedule = (disarmTime, armTime, selectedDays, tz) => {
  const data = { disarmTime, armTime, selectedDays };
  data.disarmTime = parseInt(disarmTime.hour, 10) + (disarmTime.min / 15) * 0.25 - tz;
  data.armTime = parseInt(armTime.hour, 10) + (armTime.min / 15) * 0.25 - tz;
  const newSchedules = selectedDays.map((day) => ({
    armTime: data.armTime + 24 * day,
    disarmTime: data.disarmTime + 24 * day,
  }));
  return newSchedules;
};

const eventTimeFormatted = (evt, hourMinute, hourMinuteFromNow, tz = 0) => {
  const hour = Math.trunc(hourMinute % 24);
  const min = Math.trunc(((hourMinute % 24) - hour) * 60);

  const date = new Date(new Date().getTime() + hourMinuteFromNow * 3600 * 1000);
  const dateIso = date.toISOString();
  const day = Math.trunc(date.getUTCDay() === 0 ? 6 + tz / 24 : date.getUTCDay() - 1 + tz / 24) % 7;
  const weekday = daysInWeek[day];

  const timeStr = `${(hour < 10 ? 0 : '') + hour.toString()}:${
    min < 10 ? '0' : ''
  }${min.toString()}`;
  return {
    nextEvent: {
      time: timeStr,
      weekday,
      action: evt === armingEvents.ARMING ? 'Arming' : 'Disarming',
      date: dateIso,
    },
  };
};

export const findNextEvent = (scheduleArray, tz = 0.0) => {
  if (scheduleArray.length === 0) return null;

  const d = new Date();
  const day = d.getUTCDay() === 0 ? 6 : d.getUTCDay() - 1;
  const hours = d.getUTCHours();
  const minutes = d.getUTCMinutes();
  const currentHour = 24 * day + hours + minutes / 60;
  const currentEvent = scheduleArray.filter(
    (evt) => evt.disarmTime <= currentHour && evt.armTime >= currentHour,
  );

  if (currentEvent.length > 0) {
    return eventTimeFormatted(
      armingEvents.ARMING,
      currentEvent[0].armTime - day * 24,
      currentEvent[0].armTime - currentHour,
      tz,
    );
  }

  const nextEvent = scheduleArray.filter((evt) => evt.disarmTime > currentHour);
  if (nextEvent.length > 0) {
    return eventTimeFormatted(
      armingEvents.DISARMING,
      nextEvent[0].disarmTime - day * 24,
      nextEvent[0].disarmTime - currentHour,
      tz,
    );
  }
  return eventTimeFormatted(
    armingEvents.DISARMING,
    scheduleArray[0].disarmTime + 7 * 24 - day * 24,
    scheduleArray[0].disarmTime + 7 * 24 - currentHour,
    tz,
  );
};

export const updateScheduleElement = (setSchedules, data) => {
  if (data.disarmTime === 0 && data.armTime === 0) return;

  if (data.disarmTime > 0 && data.armTime === 0) {
    data.armTime = 24;
  }

  setSchedules((currentSchedules) => {
    const updatedSchedules = { ...currentSchedules };

    if (!updatedSchedules[data.day]) {
      updatedSchedules[data.day] = [];
    }

    if (data.type === 'DELETE') {
      updatedSchedules[data.day].splice(data.idx, 1);
    } else {
      updatedSchedules[data.day][data.idx] = {
        disarmTime: data.disarmTime,
        armTime: data.armTime,
      };
    }

    updatedSchedules[data.day] = groupScheduleArray(updatedSchedules[data.day]);
    return updatedSchedules;
  });
};
