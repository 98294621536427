import backend from './backend';
import store from '../store/store';

const updateFavourites = (data) => {
  const siteId = store.getState().siteId;
  return backend.patch(`/user/favourites/${siteId}`, data);
};

const getFavourites = () => {
  const siteId = store.getState().siteId;
  if (siteId) return backend.get(`/user/favourites/${siteId}`);
  return Promise.resolve({});
};

const sensors = {
  updateFavourites,
  getFavourites,
};

export default sensors;
