import React from 'react';
import Plot from 'react-plotly.js';
import { TEMPERATURE_KEY, HUMIDITY_KEY } from './heaterFields';
import colors from '../../helpers/colors.json';

const HeaterChart = ({ meta, data, isHomePreview }) => {
  const dateArray = data.map((item) => item.time);
  const name = meta?.name;

  const temperatureTraces = [
    {
      x: dateArray,
      y: data.map((item) => parseFloat(item[TEMPERATURE_KEY])),
      mode: 'lines',
      type: 'scatter',
      name: 'Temperature',
      yaxis: 'y1',
      line: {
        color: colors.primary,
      },
    },
  ];

  const humidityTraces = [
    {
      x: dateArray,
      y: data.map((item) => item[HUMIDITY_KEY]),
      mode: 'lines',
      type: 'scatter',
      name: 'Humidity',
      yaxis: 'y2',
      line: {
        color: colors.secondary,
      },
    },
  ];

  const chartData = [...temperatureTraces, ...humidityTraces];

  return (
    <div className="Main-content">
      <div style={{ textAlign: 'center' }}>
        <Plot
          data={chartData}
          style={{
            height: isHomePreview ? '450px' : '300px',
            minWidth: '280px',
          }}
          layout={{
            autosize: true,
            plot_bgcolor: 'rgba(0,0,0,0)',
            paper_bgcolor: isHomePreview ? colors.lightText : colors.transparent,
            hovermode: 'x',
            font: {
              color: '#16205B',
            },
            title: {
              text: isHomePreview && name,
            },
            xaxis: {
              gridcolor: colors.transparent,
              type: 'date',
              tickformat: '%b %d',
              hoverformat: '%b %d, %Y %H:%M:%S',
            },
            yaxis: {
              gridcolor: colors.mediumText,
              title: 'Temperature',
              side: 'left',
            },
            yaxis2: {
              gridcolor: colors.transparent,
              title: 'Humidity',
              side: 'right',
              overlaying: 'y',
            },
            legend: {
              orientation: 'h',
              y: -0.5,
              x: 0.5,
              xanchor: 'center',
              yanchor: 'bottom',
            },
          }}
          useResizeHandler={true}
          config={{ responsive: true }}
        />
      </div>
    </div>
  );
};

export default HeaterChart;
