import io from 'socket.io-client';
import setupEvents from './events';
import config from '../../../config';

class SocketClient {
  constructor() {
    this.socket = null;
    const currentSession = localStorage.getItem('session');
    if (currentSession) this.startSocket(currentSession);
  }

  startSocket = (token) => {
    if (this.socket) this.endSocket();

    this.socket = io(config.socketUrl, {
      reconnectionDelayMax: 10000,
      query: { token },
    });

    setupEvents(this.socket);
  };

  endSocket = () => {
    this.socket.close();
  };

  emit = (topic, data) =>
    new Promise((resolve, reject) => {
      if (!this.socket) reject(new Error('No socket connection'));
      try {
        this.socket.emit(topic, data);
        resolve();
      } catch (err) {
        reject(new Error('Connection error'));
      }
    });
}

const socketClient = new SocketClient();
export default socketClient;
