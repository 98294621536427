import React from 'react';
import { useTranslation } from 'react-i18next';
import { stateTypes } from '../helpers/siteStates';

const StateDescription = ({ currentState, inprogress }) => {
  const { t } = useTranslation();
  let text;
  let pClass;

  const effectiveInProgress = inprogress.filter((item) => item.type !== 'siren');

  switch (currentState) {
    case stateTypes.TRANSITION:
      text = `... ${t('home.in_transition')}`;
      pClass = 'Status-yellow';
      break;
    case stateTypes.ARMED:
      if (effectiveInProgress.length > 0) {
        text = t('home.disarmingprogress');
        pClass = 'Status-lightGreen';
      } else {
        text = t('home.armed');
        pClass = 'Status-red';
      }
      break;
    case stateTypes.DISARMED:
      if (effectiveInProgress.length > 0) {
        text = t('home.armingprogress');
        pClass = 'Status-red';
      } else {
        text = t('home.disarmed');
        pClass = 'Status-lightGreen';
      }
      break;
    case stateTypes.MIXED:
      text = t('home.mixed');
      pClass = 'Status-darkGrey';
      break;
    default:
      text = '';
      pClass = 'Status-darkGrey';
      break;
  }
  return <p className={pClass}>{text}</p>;
};

export default StateDescription;
