import React, { useState, useEffect } from 'react';
import { ReactComponent as ScrollArrow } from '../images/arrow-up.svg';

const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const options = { passive: true };
    window.addEventListener('scroll', toggleVisible, options);
    return () => {
      window.removeEventListener('scroll', toggleVisible, options);
    };
  }, []);

  return (
    <button
      type="button"
      className="Scroll-button"
      onClick={scrollToTop}
      style={{ display: visible ? 'block' : 'none' }}
    >
      <ScrollArrow
        className="Button-element"
        stroke="var(--primary)"
        alt="button-element"
        style={{ display: visible ? 'inline' : 'none' }}
      />
    </button>
  );
};

export default ScrollButton;
