import { SET_FAVOURITES } from './types';
import sensors from '../services/favourites';

export const fetchFavourites = (callback) => (dispatch) => {
  sensors
    .getFavourites()
    .then((response) => {
      dispatch({ type: SET_FAVOURITES, payload: response.data });
      if (callback) callback(response, null);
    })
    .catch((error) => {
      if (callback) callback(null, error);
      return error;
    });
};

export const updateFavourites = (data, callback) => (dispatch) => {
  sensors
    .updateFavourites(data)
    .then(() => {
      dispatch(fetchFavourites(callback));
    })
    .catch((error) => {
      callback(null, error);
      return error;
    });
};
