function filterByName(item) {
  if (item.name !== undefined) {
    return true;
  }
  return false;
}

const sortByName = (users) => {
  const usersWithName = users.filter(filterByName);
  usersWithName.sort((a, b) => a.name.localeCompare(b.name));
  return usersWithName;
};

const removeActiveUsers = (users) => {
  const active = 'active';
  const inactiveUsers = users.filter((user) => !active.includes(user.status));
  return inactiveUsers;
};

module.exports = {
  sortByName,
  removeActiveUsers,
};
