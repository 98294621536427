import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getEvents } from '../actions/events';

const useGetEvents = () => {
  const dispatch = useDispatch();
  const fetchEvents = useCallback(() => {
    dispatch(getEvents());
  }, [dispatch]);

  return fetchEvents;
};

export default useGetEvents;
