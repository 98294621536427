import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useDataFetcher from '../../hooks/useDataFetcher';
import SensorChart from './SensorChart';
import HomePreviewTitle from '../../helpers/HomePreviewTitle';
import ArrowClick from '../../helpers/ArrowClick';
import { fetchSensor } from '../../services/fetchSensor';

const SensorPreview = ({ data, mode }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { siteId } = useParams();

  const fetchSensorData = async (service) => {
    const plotData = await fetchSensor(
      service.externalId,
      service.name,
      service.fromTime,
      service.toTime,
      service.capabilities,
    );
    return plotData;
  };

  const { fetchedData, isError } = useDataFetcher(
    data,
    fetchSensorData,
    mode,
    currentIndex,
    siteId,
  );

  const handleLeftClick = () => {
    setCurrentIndex((oldIndex) => (oldIndex - 1 + data.length) % data.length);
  };

  const handleRightClick = () => {
    setCurrentIndex((oldIndex) => (oldIndex + 1) % data.length);
  };

  const { t } = useTranslation();

  if (fetchedData.length === 0) {
    return null;
  }

  return (
    <>
      {fetchedData.map((sensor) => (
        <div key={sensor.meta.externalId}>
          <HomePreviewTitle>{t('sensor.previewTitle')}</HomePreviewTitle>
          <div style={{ margin: '6px' }}>
            <SensorChart plotData={sensor} />
            {mode !== 'favourites' && fetchedData.length > 0 && (
              <ArrowClick leftClick={handleLeftClick} rightClick={handleRightClick} />
            )}
          </div>
        </div>
      ))}
      {isError && <p>{isError}</p>}
    </>
  );
};

export default SensorPreview;
