import React from 'react';
import ErrorHandler from './ErrorHandler';
import LightServiceItem from './LightServiceItem';

const LightServicePreview = ({ currentSite, favourites }) => {
  const services = currentSite?.services?.filter((obj) => obj.type === 'light');

  const siteServices = currentSite?.state?.lights || {};

  const favouriteService = services?.filter((s) => favourites?.includes(s.externalId));

  const servicesToRender = favouriteService?.length > 0 ? favouriteService : services;

  if (!servicesToRender || servicesToRender.length === 0) return null;

  return (
    <ErrorHandler>
      {servicesToRender.map((service) => {
        const lightState = siteServices[service.externalId]?.state;
        return (
          <LightServiceItem
            key={service.externalId}
            lightState={lightState}
            externalId={service.externalId}
            item={service}
          />
        );
      })}
    </ErrorHandler>
  );
};

export default LightServicePreview;
