import axios from 'axios';
import config from '../config';
import { SET_ACCESS_TOKEN, CLEAR_ACCESS_TOKEN, LOGOUT } from '../actions/types';
import store from '../store/store';
import { authHeader, getSessionToken } from './auth-header';

const API_URL = config.apiUrl;
const backend = axios.create({
  baseURL: API_URL,
});

backend.interceptors.request.use(
  (req) => {
    req.headers = { ...req.headers, ...authHeader() };
    return req;
  },
  (error) => Promise.reject(error),
);

backend.interceptors.response.use(
  (response) =>
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    response,
  (error) => {
    const originalRequest = error.config;
    const session = getSessionToken();

    if (error.response?.status === 407 && !originalRequest.retry && session) {
      originalRequest.retry = true;
      return axios
        .get(`${API_URL}/auth/refresh/${session}`)
        .then((res) => {
          if (res?.data?.token) {
            store.dispatch({
              type: SET_ACCESS_TOKEN,
              payload: { token: res.data.token },
            });
          }
          return backend(originalRequest);
        })
        .catch((err) => {
          // Our session-token is no good! Log out the user
          localStorage.clear();
          store.dispatch({
            type: CLEAR_ACCESS_TOKEN,
          });
          store.dispatch({
            type: LOGOUT,
          });
          return Promise.reject(err);
        });
    }
    return Promise.reject(error);
  },
);

export default backend;
