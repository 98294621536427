import { USERS_LIST, CLEAR_USERS } from '../actions/types';
import { sortByName, removeActiveUsers } from '../helpers/sortByName';

const initialState = { invitedUsers: [], activeUsers: [] };

export default function usersList(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case USERS_LIST:
      if (payload) {
        const activeUsers = sortByName(payload);
        const invitedUsers = removeActiveUsers(payload);
        return { ...state, invitedUsers, activeUsers };
      }
      return state;
    case CLEAR_USERS:
      return null;
    default:
      return state;
  }
}
