const timeDifferenceOptimal = (t, t2, limit) => {
  const time = Date.parse(t);
  const time2 = Date.parse(t2);
  if (time2 < time + limit) return true;
  return false;
};

const optimalTime = {
  timeDifferenceOptimal,
};

export default optimalTime;
