/* eslint-disable react/prop-types */
import React, { Suspense } from 'react';
import './styles/style.scss';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import PageLoader from './components/PageLoader';
import Home from './pages/Home';
import history from './helpers/history';
import ProtectedRoutes from './helpers/ProtectedRoutes';
import { OnlineStatusProvider } from './hooks/useOnlineStatus';
import OfflineNotification from './components/OfflineNotification';
import { ThemeProvider } from './context/ThemeContext';

function App() {
  const LandingPage = React.lazy(() => import('./pages/LandingPage'));
  const Signup = React.lazy(() => import('./pages/Signup'));
  const Privacy = React.lazy(() => import('./pages/Privacy'));
  const ProfileSettings = React.lazy(() => import('./pages/ProfileSettings'));
  const UserSettings = React.lazy(() => import('./pages/UserSettings'));
  const DeleteUser = React.lazy(() => import('./pages/DeleteUser'));
  const CreateUser = React.lazy(() => import('./pages/CreateUser'));
  const AllUsers = React.lazy(() => import('./pages/AllUsers'));
  const CreateWorksite = React.lazy(() => import('./pages/CreateWorksite'));
  const WorksiteSettings = React.lazy(() => import('./pages/WorksiteSettings'));
  const DeleteWorksite = React.lazy(() => import('./pages/DeleteWorksite'));
  const HelpCenter = React.lazy(() => import('./pages/HelpCenter'));
  const CookiePolicy = React.lazy(() => import('./pages/CookiePolicy'));
  const ReleaseNotes = React.lazy(() => import('./pages/ReleaseNotes'));
  const ForgotPassword = React.lazy(() => import('./pages/ForgotPassword'));
  const ResetPasswordEmail = React.lazy(() => import('./pages/ResetPasswordEmail'));
  const ResetPassword = React.lazy(() => import('./pages/ResetPassword'));
  const VerifyAlarm = React.lazy(() => import('./pages/VerifyAlarm'));
  const VerifyVideo = React.lazy(() => import('./pages/VerifyVideo'));
  const Public = React.lazy(() => import('./pages/PublicImage'));
  const NoMatch = React.lazy(() => import('./pages/NoMatch'));
  const Schedule = React.lazy(() => import('./pages/Schedule'));
  const CameraFeed = React.lazy(() => import('./pages/CameraFeed'));
  const ScheduleSettings = React.lazy(() => import('./pages/ScheduleSettings'));
  const Services = React.lazy(() => import('./pages/Services'));
  const Sensors = React.lazy(() => import('./pages/Sensors'));
  const InvitedUsers = React.lazy(() => import('./pages/InvitedUsers'));
  const Gates = React.lazy(() => import('./pages/Gates'));
  const Security = React.lazy(() => import('./pages/Security'));
  const Lights = React.lazy(() => import('./pages/Lights'));
  const Heater = React.lazy(() => import('./pages/Heater'));
  const Consumption = React.lazy(() => import('./pages/Consumption'));
  const AllSensors = React.lazy(() => import('./pages/AllSensors'));
  const ReportForm = React.lazy(() => import('./pages/ReportForm'));
  const NavbarLayout = React.lazy(() => import('./layout/NavbarLayout'));

  return (
    <OnlineStatusProvider>
      <Router history={history}>
        <ThemeProvider>
          <Suspense fallback={<PageLoader />}>
            <OfflineNotification />
            <Routes history={history.pathname}>
              <Route path="/signup/:key/:email" element={<Signup />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/cookie-policy" element={<CookiePolicy />} />
              <Route path="/login" element={<LandingPage />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password-email/:key/:email" element={<ResetPasswordEmail />} />
              <Route path="/verify/:magicToken" element={<VerifyAlarm />} />
              <Route path="/verify/:token/video" element={<VerifyVideo />} />
              <Route path="/public/:worksiteName" element={<Public />} />
              <Route path="/public/" element={<Public />} />
              <Route element={<ProtectedRoutes />}>
                <Route path="/profile-settings" element={<ProfileSettings />} />
                <Route path="/user/:email/user-settings" element={<UserSettings />} />
                <Route path="/user/:email/delete-user" element={<DeleteUser />} />
                <Route path="/user/delete-user" element={<DeleteUser />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/create-worksite" element={<CreateWorksite />} />
                <Route path="/create-user" element={<CreateUser />} />
                <Route path="/site/:siteId/worksite-settings" element={<WorksiteSettings />} />
                <Route path="/site/:siteId/delete" element={<DeleteWorksite />} />
                <Route path="/site" element={<Home />} />
                <Route path="/site/:siteId" element={<NavbarLayout />}>
                  <Route path="" element={<Home />} />
                  <Route path="security" element={<Security />} />
                  <Route path="schedule" element={<Schedule />} />
                  <Route path="sensor" element={<Sensors />} />
                  <Route path="sensor/report" element={<ReportForm />} />
                  <Route path="all-sensors" element={<AllSensors />} />
                  <Route path="gates" element={<Gates />} />
                  <Route path="lights" element={<Lights />} />
                  <Route path="heater" element={<Heater />} />
                  <Route path="heater/report" element={<ReportForm />} />
                  <Route path="consumption" element={<Consumption />} />
                  <Route path="camera-feed" element={<CameraFeed />} />
                  <Route path="schedule-settings" element={<ScheduleSettings />} />
                  <Route path="invitedusers" element={<InvitedUsers />} />
                  <Route path="*" element={<NoMatch />} />
                </Route>
                <Route element={<NavbarLayout />}>
                  <Route path="/services/:service" element={<Services />} />
                </Route>
                <Route path="/allusers" element={<AllUsers />} />
                <Route path="/help" element={<HelpCenter />} />
                <Route path="/release-notes" element={<ReleaseNotes />} />
              </Route>
              <Route path="/" element={<Navigate to="/site" />} />
              <Route element={<NoMatch />} />
            </Routes>
          </Suspense>
        </ThemeProvider>
      </Router>
    </OnlineStatusProvider>
  );
}

export default App;
