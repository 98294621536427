/* eslint-disable react/prop-types */
import React from 'react';
import { ReactComponent as Trash } from '../images/trash.svg';

function TrashButton(props) {
  const type = props.type;
  let cb = () => {};
  if (props.cb) cb = props.cb;

  let cbArgs = {};
  if (props.cbArgs) cbArgs = props.cbArgs;

  return (
    <button
      type={type === 'button' ? 'button' : 'submit'}
      className="Trash-button"
      onClick={() => cb(cbArgs)}
    >
      {props.text}
      <Trash alt="Trash-icon" className="Trash-icon" />
    </button>
  );
}

export default TrashButton;
