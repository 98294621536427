import React from 'react';
import HomePreviewTitle from '../../helpers/HomePreviewTitle';
import SensorChart from './SensorChart';
import ArrowClick from '../../helpers/ArrowClick';

const SensorPreview = ({
  sensor,
  mode,
  fetchedData,
  sensorType,
  handleLeftClick,
  handleRightClick,
}) => {
  if (!sensor || !sensor.data) {
    return null;
  }

  const sensorTitle = sensorType === 'invisense' ? 'Concrete sensor data' : 'Screed sensor data';

  return (
    <div key={sensor.meta?.externalId}>
      <HomePreviewTitle>{sensorTitle}</HomePreviewTitle>
      <div style={{ margin: '6px' }}>
        <SensorChart plotData={sensor} isHomePreview={true} />

        {mode !== 'favourites' && fetchedData.length > 0 && (
          <ArrowClick leftClick={handleLeftClick} rightClick={handleRightClick} />
        )}
      </div>
    </div>
  );
};

export default SensorPreview;
