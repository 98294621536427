const normalizingData = (arr, idName, sortCol, sortDir = 1) => {
  const sortByCol = (a, b) => {
    if (!sortCol) return 0;
    let c = 1;
    if (sortDir) c = 1;
    else c = -1;
    let ai;
    let bi;
    if (typeof a[sortCol] === 'string') ai = a[sortCol].toUpperCase();
    else ai = a[sortCol];
    if (typeof b[sortCol] === 'string') bi = b[sortCol].toUpperCase();
    else bi = b[sortCol];

    if (ai < bi) return -1 * c;
    if (ai > bi) return 1 * c;
    return 0;
  };

  const obj = {};
  const id = [];
  arr.sort(sortByCol).forEach((el) => {
    id.push(el[idName]);
    obj[el[idName]] = el;
  });

  return { obj, id };
};
export default normalizingData;
