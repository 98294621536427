/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { formatTime } from '../helpers/formatTime';
import TimelineImage from './TimelineImage';
import { ReactComponent as Calendar } from '../images/calendar.svg';
import Events from './Events';
import ImageReceived from './ImageReceived';

const Timelineitem = (props) => {
  const [highlight, setHighlight] = useState(null);
  const [highlightedImages, setHighlightedImages] = useState(null);
  const { t } = useTranslation();
  let date = 'N/A';
  let time = 'N/A';
  const siteId = props.item[0].siteId;
  const eventDate = props.item.filter((el) => el.time)[0]?.time;
  const calendarClickedDate = props.calendarDate;
  const timelineRef = useRef(null);

  useEffect(() => {
    if (props.triggeredTime) {
      const times = props.item.filter((el) => el.time);
      times.forEach((el) => {
        if (el.time === props.triggeredTime) setHighlight(true);
      });
    }

    if (highlight) {
      const view = document.getElementById('view');
      view?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [eventDate, props.triggeredTime, highlight, props.item]);

  useLayoutEffect(() => {
    if (timelineRef.current) {
      const timelineElement = timelineRef.current;
      const timelineItem = timelineElement.querySelector(`[data-date="${calendarClickedDate}"]`);
      if (timelineItem) {
        timelineElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [calendarClickedDate, timelineRef]);

  if (!Array.isArray(props.item) || props.item.length === 0) return <></>;

  if (typeof eventDate !== 'undefined') {
    const finaleventDate = formatTime(eventDate);
    date = finaleventDate.date;
    time = finaleventDate.time;
  }

  const unique = (value, index, self) => self.indexOf(value) === index;

  const imgUrls = props.item
    .map(({ imgUrl }) => imgUrl)
    .reverse()
    .filter((imgUrl) => imgUrl);

  const worksiteObject = props.worksiteList?.worksiteObjects?.obj[siteId];
  const zonesFromStates = worksiteObject?.state?.states;
  const siaZones = zonesFromStates
    ? props.item
        .map(({ areaId }) =>
          areaId && zonesFromStates[areaId] ? zonesFromStates[areaId].siaZone : undefined,
        )
        .filter(unique)
        .join(', ')
    : [];

  const names = zonesFromStates
    ? props.item
        .map(({ areaId }) =>
          areaId && zonesFromStates[areaId] ? zonesFromStates[areaId].name : undefined,
        )
        .filter(unique)
        .join(', ')
    : [];

  const areaIds = props.item
    .map(({ areaId }) => areaId)
    .filter((areaId) => areaId)
    .filter(unique)
    .join(', ');

  const zones = siaZones > 0 ? siaZones : areaIds;

  return (
    <div className="Width-xl" id={highlight ? 'view' : null} data-testid="timeline-item">
      <div className="Row-container-simple" ref={timelineRef}>
        <div className="Timeline-time" key={props.item.index}>
          <p data-date={date}>{time}</p>
        </div>
        <div className={highlight ? 'Timeline-action-highlited' : 'Timeline-action '}>
          {/* Top line */}
          <div className="Row-container Width-xl">
            <Events item={props.item} />
            {zones ? (
              <div className="Text-align-right">
                {names && <p className="Padding-txs">{names}</p>}
                <p className="Sm-label-gr">{t('timeline.area')}</p>
                <p className="Sm-label-gr">{zones}</p>
              </div>
            ) : null}
          </div>
          {/* Top line ends */}
          <div className="Row-container Width-xl Align-bottom">
            <div className="Padding-txs Row-container Align-center Height-xxl">
              <Calendar fill="#16205B" />
              <p className="Margin-lxs Date">{date}</p>
            </div>
            {props.item[0].email ? (
              <div className="Text-align-right">
                <p className="Sm-label-gr">{t('user.user')}</p>
                <p className="Email">{props.item[0].email}</p>
              </div>
            ) : null}
            {!imgUrls && props.externalLink && (
              <a href={props.externalLink} target="_blank" rel="noreferrer">
                <button className="Secondary-grey-button" type="button">
                  External Link
                </button>
              </a>
            )}
            <ImageReceived item={props.item} cb={(urls) => setHighlightedImages(urls)} />
          </div>
        </div>
      </div>
      {imgUrls.length > 0 ? (
        <div className="Timeline-images">
          <TimelineImage
            imgUrl={imgUrls}
            animateImages={imgUrls.length > 1}
            // array with links which images should be highlighted
            highlightedImages={highlightedImages}
          />
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  worksiteList: state.worksiteList,
  item: ownProps.item,
  triggeredTime: ownProps.triggeredTime,
});

export default connect(mapStateToProps)(Timelineitem);
