/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import {
  format,
  addDays,
  subDays,
  isSameMonth,
  isSameDay,
  startOfWeek,
  endOfWeek,
  subMonths,
} from 'date-fns';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getWeeklyEvents } from '../actions/events';
import ArrowClickRight from '../helpers/ArrowClickRight';
import ArrowClickLeft from '../helpers/ArrowClickLeft';
import PageScrolled from './PageScrolled';

const CalendarView = ({
  eventAlarm,
  currentDate,
  setCurrentDate,
  handleDateClick,
  fetchWeeklyEvents,
}) => {
  const [currentMonth, setCurrentMonth] = useState(currentDate);
  const [isNextWeekInFuture, setIsNextWeekInFuture] = useState(false);
  const { t } = useTranslation();
  const monday = startOfWeek(currentDate, { weekStartsOn: 1 });
  const sunday = endOfWeek(currentDate, { weekStartsOn: 1 });

  const isInSameMonth = isSameMonth(monday, sunday);

  const location = useLocation().pathname;

  useEffect(() => {
    setCurrentDate(new Date());
    setCurrentMonth(new Date());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    setIsNextWeekInFuture(addDays(currentDate, 7) <= new Date());
  }, [currentDate]);

  const handlePrevWeekClick = useCallback(() => {
    const newDate = subDays(currentDate, 7);
    const weekStart = startOfWeek(newDate, { weekStartsOn: 1 });
    const weekEnd = endOfWeek(newDate, { weekStartsOn: 1 });

    if (!isSameMonth(newDate, currentMonth)) {
      setCurrentMonth(newDate);
    }
    setCurrentDate(newDate);
    setIsNextWeekInFuture(true);
    fetchWeeklyEvents(weekStart.toISOString(), weekEnd.toISOString());
  }, [
    currentDate,
    currentMonth,
    setCurrentMonth,
    setCurrentDate,
    setIsNextWeekInFuture,
    fetchWeeklyEvents,
  ]);

  const handleNextWeekClick = useCallback(() => {
    const nextWeekStartDate = addDays(currentDate, 7);
    if (nextWeekStartDate <= new Date()) {
      const newDate = nextWeekStartDate;
      if (!isSameMonth(newDate, currentMonth)) {
        setCurrentMonth(newDate);
      }
      setCurrentDate(newDate);
    }
    setIsNextWeekInFuture(false);
  }, [currentDate, currentMonth, setCurrentMonth, setCurrentDate, setIsNextWeekInFuture]);

  const weekStartDate = startOfWeek(currentDate, { weekStartsOn: 1 });
  const days = Array.from({ length: 7 }, (_, i) => addDays(weekStartDate, i));

  const convertDate = useCallback((date) => {
    const yyyy = date.getFullYear();
    const mm = date.getMonth() + 1;
    const dd = date.getDate();
    return `${yyyy}-${mm.toString().padStart(2, '0')}-${dd.toString().padStart(2, '0')}`;
  }, []);

  const lastMonthDate = subMonths(sunday, 1);

  return (
    <div className="Calendar">
      <PageScrolled>
        <div className="Calendar__month">
          {isInSameMonth ? (
            <h3 className="Calendar__month__current">
              {t(`calendar.months.${format(currentMonth, 'MMMM')}`, {
                defaultValue: format(currentMonth, 'MMMM'),
              })}
            </h3>
          ) : (
            <>
              <h3 className="Calendar__month__previous">
                {t(`calendar.months.${format(lastMonthDate, 'MMMM')}`, {
                  defaultValue: format(lastMonthDate, 'MMMM'),
                })}
              </h3>
              <h3 className="Calendar__month__current">
                {t(`calendar.months.${format(sunday, 'MMMM')}`, {
                  defaultValue: format(sunday, 'MMMM'),
                })}
              </h3>
            </>
          )}
        </div>
        <div className="Calendar__body">
          <ArrowClickLeft leftClick={handlePrevWeekClick} />
          <div className="Calendar__dates">
            {days.map((day) => {
              const eventsOnDay = eventAlarm.filter((ev) => isSameDay(ev.time, day));
              const formattedDate = convertDate(day);
              const dayOfWeek = format(day, 'EEEE');
              const shortDay = t(`calendar.days.${dayOfWeek}`).slice(0, 3);
              return (
                <div key={day} className="Day-container">
                  <h4>{shortDay}</h4>
                  <button
                    type="button"
                    className={`Reset-button-styles day ${
                      isSameDay(day, new Date()) ? 'today' : ''
                    }`}
                    onClick={() => handleDateClick(formattedDate)}
                    onKeyDown={() => handleDateClick(formattedDate)}
                  >
                    {day.getDate()}
                  </button>
                  {eventsOnDay.length > 0 && <span className="Event-dot" />}
                </div>
              );
            })}
          </div>
          {isNextWeekInFuture && <ArrowClickRight rightClick={handleNextWeekClick} />}
        </div>
      </PageScrolled>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchWeeklyEvents: (from, to) => dispatch(getWeeklyEvents(from, to)),
});

export default connect(null, mapDispatchToProps)(CalendarView);
