import { ADD_GROUPED_EVENTS, CLEAR_GROUPED_EVENTS, ADD_EVENTS_TO_GROUPS } from '../actions/types';
import { groupTimelineEvents } from '../helpers/groupEvents';

export default function groupedEvents(state = [], action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_GROUPED_EVENTS:
      if (Array.isArray(state)) {
        if (Array.isArray(payload)) {
          return state.concat(payload);
        }
        return state;
      }
      return [];
    case CLEAR_GROUPED_EVENTS:
      return [];
    case ADD_EVENTS_TO_GROUPS:
      return groupTimelineEvents(payload, state, 6, true);
    default:
      return state;
  }
}
