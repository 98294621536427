import React from 'react';
import DeviceCard from './DeviceCard';
import AnimatedTransitionVertical from './AnimatedTransitionVertical';
import { sortStates } from '../helpers/siteStates';

function DevicesPanel({ states, renderDevicesPanel }) {
  const sortedStates = sortStates(states);

  return (
    <AnimatedTransitionVertical isVisible={renderDevicesPanel}>
      <div className="Blue-line Margin-vs Margin-bl">
        {sortedStates.map((state) => (
          <DeviceCard item={state} key={`dc-${state.areaId}-${state.updated}`} />
        ))}
      </div>
    </AnimatedTransitionVertical>
  );
}

export default DevicesPanel;
