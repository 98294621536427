import React from 'react';
import { ReactComponent as ArrowRight } from '../images/arrow-right-blue.svg';

const ArrowClickRight = ({ rightClick }) => (
  <button
    type="button"
    className="Reset-button-styles Pointer"
    aria-label="Arrow right"
    onClick={rightClick}
  >
    <ArrowRight style={{ padding: '1rem' }} />
  </button>
);

export default ArrowClickRight;
