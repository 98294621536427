/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */ import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

SwiperCore.use([Navigation, Pagination]);

export default function Swipe({ slides }) {
  return (
    <Swiper spaceBetween={0} pagination clickable="true" slidesPerView={1}>
      {slides && slides.map((slide, i) => <SwiperSlide key={i.toString()}>{slide}</SwiperSlide>)}
    </Swiper>
  );
}
