import React from 'react';
import ArrowClick from '../../helpers/ArrowClick';
import HomePreviewTitle from '../../helpers/HomePreviewTitle';
import HeaterChart from './HeaterChart';

// eslint-disable-next-line react/function-component-definition
const HeaterPreview = ({ heater, mode, fetchedData, handleLeftClick, handleRightClick }) => {
  if (!heater || !heater.data) {
    return null;
  }

  return (
    <div key={heater.meta?.externalId}>
      <HomePreviewTitle>Heater data</HomePreviewTitle>
      <div style={{ margin: '6px' }}>
        <HeaterChart meta={heater.meta} data={heater.data} isHomePreview={true} />
        {mode !== 'favourites' && fetchedData.length > 0 && (
          <ArrowClick leftClick={handleLeftClick} rightClick={handleRightClick} />
        )}
      </div>
    </div>
  );
};

export default HeaterPreview;
