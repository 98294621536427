import React, { useState, useMemo, useEffect } from 'react';
import { motion } from 'framer-motion';
import DevicesPanel from './DevicesPanel';
import { ReactComponent as Down } from '../images/arrow-down.svg';
import colors from '../helpers/colors.json';
import createDeviceElements from '../helpers/createDeviceElements';
import AnimatedTransitionVertical from './AnimatedTransitionVertical';

function ArmingPanel({ states }) {
  const [isVisible, setIsVisible] = useState(false);
  const [renderDevicesPanel, setRenderDevicesPanel] = useState(false);

  const deviceElements = useMemo(() => createDeviceElements(states), [states]);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <AnimatedTransitionVertical isVisible={isVisible}>
      <motion.div initial={false} animate={renderDevicesPanel ? 'open' : 'closed'}>
        <motion.button
          className="Arming-panel Pointer Toggle-arrow"
          onClick={() => setRenderDevicesPanel(!renderDevicesPanel)}
          whileTap={{ scale: 0.98 }}
          type="button"
        >
          <span className="Row-container-simple-center">{deviceElements}</span>
          <motion.div
            className="Arrow"
            variants={{
              open: { rotate: 180 },
              closed: { rotate: 0 },
            }}
            transition={{ duration: 0.2 }}
          >
            <Down stroke={colors.text} />
          </motion.div>
        </motion.button>
        <DevicesPanel states={states} renderDevicesPanel={renderDevicesPanel} />
      </motion.div>
    </AnimatedTransitionVertical>
  );
}

export default ArmingPanel;
