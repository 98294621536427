import React, { useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import Hls from 'hls.js';

// eslint-disable-next-line react/function-component-definition
const CameraPlayerImoulife = ({ src, hideLoader }) => {
  const playerRef = useRef(null);

  useEffect(() => {
    if (Hls.isSupported()) {
      const hls = new Hls();
      if (src) {
        hls.loadSource(src);
        hls.attachMedia(playerRef.current);
      }
    }
  }, [src, playerRef]);

  return (
    <div>
      {playerRef === null ? (
        <p>This browser does not support hls streams.</p>
      ) : (
        <ReactPlayer
          className="Camera-box__video"
          onReady={hideLoader}
          ref={playerRef}
          url={src}
          controls={true}
          width="100%"
          height="360px"
          playing={true}
          muted={true}
          volume={null}
          playsinline={true}
        />
      )}
    </div>
  );
};

export default CameraPlayerImoulife;
