import React from 'react';

const Button = ({
  type,
  className = 'Primary-button',
  onClick,
  text = '',
  size = 'big',
  disable,
  testid = '',
}) => {
  let sizeClass = '';
  switch (size) {
    case 'small':
      sizeClass = 'smallButton';
      break;
    case 'medium':
      sizeClass = 'mediumButton';
      break;
    default:
      sizeClass = 'bigButton';
  }

  const buttonClass = `${className} ${sizeClass}`;

  return (
    <button
      type={type === 'submit' ? 'submit' : 'button'}
      disabled={disable}
      className={buttonClass}
      onClick={onClick}
      data-testid={testid}
    >
      {text}
    </button>
  );
};

export default Button;
