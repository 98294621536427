import backend from './backend';
import store from '../store/store';

const getWeeklyEvents = (from, to) => {
  const siteId = store.getState().siteId;
  if (siteId) return backend.get(`/event/site/${siteId}?items=3000&from=${from}&to=${to}`);
  return Promise.resolve({});
};

const calendarservice = {
  getWeeklyEvents,
};

export default calendarservice;
