import {
  WORKSITE_LIST_FETCHING,
  WORKSITE_LIST,
  UPDATE_WORKSITES,
  DELETE_WORKSITE,
  WORKSITE_SUCCES,
  WORKSITE_UPDATE,
  WORKSITE_UPDATE_EXTERNAL,
  WORKSITE_UPDATE_VIDEO,
  DELETE_WORKSITE_EXTERNAL,
  SET_SITE_ID,
  UPDATE_SIA_ZONE,
} from './types';

import worksiteservice from '../services/worksite.service';
import showError from './error';
import { clearEvents } from './events';
import { clearSchedule } from './schedule';

import store from '../store/store';

export const setWorksite = (itemId) => (dispatch) => {
  let setSiteId = itemId;
  const siteId = store.getState().siteId;
  const checkId = store.getState().worksiteList?.worksiteObjects?.obj[itemId];

  if (typeof setSiteId !== 'string' || setSiteId.length <= 1 || !checkId) {
    setSiteId = localStorage.getItem('worksiteId');

    if (typeof setSiteId !== 'string' || setSiteId.length <= 1) {
      const sites = store.getState().worksiteList.worksites;
      if (sites.length > 0) setSiteId = sites[0].siteId;
    }
  }

  if (setSiteId !== siteId && typeof setSiteId === 'string') {
    localStorage.setItem('worksiteId', setSiteId);
    dispatch({
      type: SET_SITE_ID,
      payload: setSiteId,
    });

    dispatch(clearEvents());
    dispatch(clearSchedule());
  }
};

export const armWorksite = (siteId, state, cb) => () => {
  worksiteservice
    .armSite(siteId, state)
    .then(() => {
      cb();
    })
    .catch((err) => {
      cb(err);
      return err;
    });
};

export const armDevice = (areaId, state, callback) => () => {
  worksiteservice
    .armDevice(areaId, state)
    .then(() => {
      callback();
    })
    .catch((err) => {
      callback(err.response?.data?.error || err);
      return err;
    });
};

export const deleteDevice = (areaId) => async (dispatch) => {
  await worksiteservice.deleteDevice(areaId).catch(() => {});
  worksiteservice
    .getWorksites()
    .then((response) => {
      dispatch({
        type: WORKSITE_LIST,
        payload: { worksites: response.data },
      });
    })
    .catch(() => {});
};

export const updateWorksites = () => (dispatch) => {
  const installer = store.getState().auth.user.installer;
  dispatch({ type: WORKSITE_LIST_FETCHING });
  worksiteservice
    .getWorksites()
    .then((response) => {
      dispatch({
        type: UPDATE_WORKSITES,
        payload: {
          worksites: response.data,
          installer,
        },
      });
    })
    .catch(() => {});
};

export const deleteWorksite = (siteId, callback) => async (dispatch) => {
  await worksiteservice
    .deleteWorksite(siteId)
    .then((response) => {
      dispatch({
        type: DELETE_WORKSITE,
      });
      callback(response, null);
    })
    .catch((error) => {
      callback(null, showError(error));
    });
  worksiteservice
    .getWorksites()
    .then((response) => {
      dispatch({
        type: WORKSITE_LIST,
        payload: { worksites: response.data },
      });
    })
    .catch(() => {});
};

export const deleteWorksiteExternal = (data, siteId, callback) => (dispatch) => {
  worksiteservice
    .deleteWorksiteExternal(data, siteId)
    .then((response) => {
      dispatch({
        type: DELETE_WORKSITE_EXTERNAL,
      });
      callback(response, null);
    })
    .catch((error) => {
      callback(null, showError(error));
      return error;
    });
};

export const createWorksite = (data, callback) => (dispatch) => {
  worksiteservice
    .createWorksite(data)
    .then((response) => {
      dispatch({
        type: WORKSITE_SUCCES,
      });
      callback(response, null);
    })
    .catch((error) => {
      callback(null, error);
      return error;
    });
};

export const updateWorksite = (data, siteId, callback) => (dispatch) => {
  worksiteservice
    .updateWorksite(data, siteId)
    .then((response) => {
      dispatch({
        type: WORKSITE_UPDATE,
      });
      callback(response, null);
    })
    .catch((error) => {
      callback(null, error);
      return error;
    });
};

export const createWorksiteExternal = (data, siteId, callback) => (dispatch) => {
  worksiteservice
    .createWorksiteExternal(data, siteId)
    .then((response) => {
      dispatch({
        type: WORKSITE_UPDATE_EXTERNAL,
      });
      callback(response, null);
    })
    .catch((error) => {
      callback(null, error);
      return error;
    });
};

export const updateWorksiteVideo = (data, siteId, callback) => (dispatch) => {
  worksiteservice
    .updateWorksiteVideo(data, siteId)
    .then((response) => {
      dispatch({
        type: WORKSITE_UPDATE_VIDEO,
      });
      callback(response, null);
    })
    .catch((error) => {
      callback(null, error);
      return error;
    });
};

export const updateZone = (areaId, data, callback) => (dispatch) => {
  const siteId = store.getState().siteId;
  worksiteservice
    .updateZone(siteId, areaId, data)
    .then((response) => {
      dispatch({
        type: UPDATE_SIA_ZONE,
      });
      callback(response, null);
    })
    .catch((error) => {
      callback(null, error);
      return error;
    });
};
