export const groupTimelineEvents = (
  events,
  prevState = [],
  timeDifference = 6,
  requireSameArea = 0,
) => {
  if (!Array.isArray(prevState)) return [];
  if (!(Array.isArray(events) && events.length > 0)) return prevState;

  const MAX_IMAGES_IN_GROUP = 8;

  const sameArea = (prevArea, newArea) => {
    if (!prevArea || !newArea || !requireSameArea) {
      return true;
    }
    const res = String(prevArea).valueOf() === String(newArea).valueOf();
    return res;
  };

  const standaloneEvent = (evt) => {
    const evts = [
      'CONNECTION FAIL',
      'COMMUNICATION RESTORE',
      'DISARMED',
      'ARMED',
      'ARM',
      'DISARM',
      'RAPID_ACTIVATE',
      'RAPID_DEACTIVATE',
      'TEMPEST_ACTIVATE',
      'TEMPEST_DEACTIVATE',
      'SECURITAS_ACTIVATE',
      'SECURITAS_DEACTIVATE',
    ];
    return evts.includes(evt);
  };

  const similarTime = (t1, t2) => {
    if (!t2) return true;
    return Math.abs(Date.parse(t1) - Date.parse(t2)) < timeDifference * 60 * 1000;
  };

  /**
   * events and prevState are already sorted.
   * Assume that last & first index will have
   * similar time.
   */
  let groupIndex = prevState.length;
  const eventGroup = [...prevState];
  try {
    if (similarTime(events[0].time, prevState[prevState.length - 1][0].time))
      groupIndex = Math.max(prevState.length - 1, 0);
  } catch (e) {
    groupIndex = prevState.length;
  }

  if (typeof groupIndex !== 'number') groupIndex = 0;

  let imagesInGroup = 0;
  if (eventGroup.length !== 0) {
    if (groupIndex > 0 && eventGroup.length === groupIndex + 1)
      imagesInGroup = eventGroup[groupIndex]
        .map(({ imgUrl }) => imgUrl)
        .filter((imgUrl) => imgUrl).length;
  }

  events.map((p) => {
    const prevIndex = eventGroup.length - 1 || 0;
    let lastAreaId = null;
    let lastTime = null;
    if (eventGroup[prevIndex])
      lastAreaId = eventGroup[prevIndex].filter((el) => el.areaId)[0]?.areaId;
    if (eventGroup[prevIndex]) lastTime = eventGroup[prevIndex].filter((el) => el.time)[0]?.time;
    if (
      !similarTime(p.time, lastTime) ||
      imagesInGroup >= MAX_IMAGES_IN_GROUP ||
      !sameArea(lastAreaId, p.areaId) ||
      (eventGroup[prevIndex] && standaloneEvent(eventGroup[prevIndex][0]?.event))
    ) {
      groupIndex = eventGroup.length;
      eventGroup[groupIndex] = [];
      imagesInGroup = 0;
      eventGroup[groupIndex].push(p);
    } else if (standaloneEvent(p.event)) {
      try {
        if (eventGroup[prevIndex][0].event === p.event) {
          eventGroup[prevIndex].push(p);
        } else {
          eventGroup.push([p]);
        }
      } catch (err) {
        eventGroup.push([p]);
      }
    } else {
      if (!Array.isArray(eventGroup[groupIndex])) eventGroup[groupIndex] = [];
      eventGroup[groupIndex].push(p);
      if (p.imgUrl) imagesInGroup += 1;
    }
    return p;
  });
  const filtered = eventGroup.filter((el) => el.length > 0);
  return filtered;
};

export const groupArmingEvents = (events) => {
  const armingStates = ['ARM', 'DISARM', 'ARMED', 'DISARMED', 'AUTOMATIC TEST'];
  const prevArray = [];
  const eventGroup = [...prevArray];
  let groupIndex = prevArray.length;
  if (typeof groupIndex !== 'number') groupIndex = 0;

  events.map((el) => {
    const prevIndex = Math.max(eventGroup.length - 1, 0);
    if (
      armingStates.includes(el[0].event) &&
      eventGroup[prevIndex] &&
      eventGroup[prevIndex].length < 15
    ) {
      if (armingStates.includes(eventGroup[prevIndex][0].event)) {
        const prevArmArray = eventGroup[prevIndex];
        eventGroup[prevIndex] = prevArmArray.concat(el);
      }
      if (!armingStates.includes(eventGroup[prevIndex][0].event)) {
        eventGroup.push(el);
      }
    }
    if (
      !armingStates.includes(el[0].event) ||
      !eventGroup[prevIndex] ||
      eventGroup[prevIndex]?.length > 15
    ) {
      eventGroup.push(el);
    }
    return eventGroup;
  });
  return eventGroup;
};
