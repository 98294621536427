import backend from './backend';
import store from '../store/store';

const createSchedule = (data, siteId, scheduleActivated, scheduleDeactivated) =>
  backend.put(`/site/${siteId}/schedule`, {
    siteInfo: data,
    scheduleActivated,
    scheduleDeactivated,
  });

const getSchedule = () => {
  const siteId = store.getState().siteId;

  if (siteId) return backend.get(`/site/${siteId}/schedule`);
  return Promise.resolve({});
};

const deleteSchedule = (siteId) => backend.delete(`/site/${siteId}/schedule/`);

const scheduleservice = {
  getSchedule,
  createSchedule,
  deleteSchedule,
};

export default scheduleservice;
