/* eslint-disable react/prop-types */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { updateSession } from '../actions/auth';
import userContext from '../context/userContext';

const ProtectedRoutes = () => {
  const session = localStorage.getItem('session');
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const installer = useSelector((state) => state.auth.user?.installer);

  if (!auth.isLoggedIn) {
    dispatch(updateSession(session));
  }
  return session ? (
    <userContext.Provider value={installer}>
      <Outlet />
    </userContext.Provider>
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedRoutes;
