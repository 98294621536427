/* eslint-disable prefer-spread */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { formatTime } from '../helpers/formatTime';
import { getSchedule } from '../actions/schedule';
import { calculateScheduleDstOffset, findNextEvent } from '../lib/schedule';
import { ReactComponent as ActiveSchedule } from '../images/schedule-active.svg';
import { ReactComponent as InactiveSchedule } from '../images/schedule-inactive.svg';
import colors from '../helpers/colors.json';

function NextScheduleEvent(props) {
  const [schedules, setSchedules] = useState(null);
  const [event, setEvent] = useState();
  const { t } = useTranslation();
  const currentWorksiteId = props.currentWorksiteId;
  let timeZone = -new Date().getTimezoneOffset() / 60;
  if (typeof props.tz === 'number') timeZone = props.tz;

  const navigate = useNavigate();

  useEffect(() => {
    if (typeof currentWorksiteId === 'string') {
      props.getSchedule();
    }
  }, [currentWorksiteId]);

  useEffect(() => {
    if (props.armingSchedule?.schedules?.length > 0 && props.armingSchedule.active) {
      setSchedules(props.armingSchedule.schedules);
    }
  }, [props.armingSchedule]);

  const capitalizeFirstLetter = (string) =>
    string ? string.charAt(0).toUpperCase() + string.slice(1) : '';

  useEffect(() => {
    if (schedules) {
      const dstAdjustment = calculateScheduleDstOffset(props.armingSchedule?.dst);
      const getEvent = findNextEvent(schedules, timeZone + dstAdjustment);
      const formatEventDate = formatTime(getEvent.nextEvent.date, null, dstAdjustment);
      getEvent.nextEvent.date = formatEventDate.date;
      getEvent.nextEvent.time = formatEventDate.time;
      setEvent(getEvent);
    }
  }, [schedules]);
  if (props.armingSchedule?.schedules?.length > 0)
    return (
      <motion.div
        initial={{ height: '0', y: -5, opacity: 0 }}
        animate={{ height: 'fit-content', y: 0, opacity: 1 }}
        exit={{ opacity: 0, y: -5 }}
        transition={{ duration: 0.3 }}
      >
        <div className="Schedule-event Margin-bs">
          {event ? (
            <div className="Row-container-simple Wrap">
              <ActiveSchedule fill={colors.text} />
              <div className="Margin-rxs Margin-lxs Row-container-gap-s Pill">
                <span>{event?.nextEvent?.time}</span>
                <div
                  className={event?.nextEvent?.action === 'Arming' ? 'Marker-red' : 'Marker-green'}
                />
                <span className="Sm-label-gr">
                  {event?.nextEvent?.action === 'Arming' ? t('schedule.arm') : t('schedule.disarm')}
                </span>
              </div>

              <span onClick={() => navigate(`schedule`)} className="Non-active Margin-rxs Pointer">
                {capitalizeFirstLetter(event.nextEvent.weekday)}, {event?.nextEvent.date}
              </span>
            </div>
          ) : (
            <div className="Row-container-gap-s ">
              <InactiveSchedule fill={colors.darkerText} />
              <p style={{ color: colors.darkerText }}>{t('schedule.inactive')}</p>
            </div>
          )}
        </div>
      </motion.div>
    );
  return null;
}

const mapStateToProps = (state) => ({
  currentWorksiteId: state.siteId,
  auth: state.auth,
  armingSchedule: state.armingSchedule,
});

const mapDispatchToProps = (dispatch) => ({
  getSchedule: () => dispatch(getSchedule()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NextScheduleEvent);
