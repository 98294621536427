import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { armDevice } from '../actions/sites';
import { ReactComponent as ButtonElement } from '../images/ArmingButtonElement.svg';
import StateButtonContent from './StateButtonContent';

const SelectStateButton = ({ item, armingResult }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [bClass, setBClass] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (item.type === 'siren' || item.type === 'camera') {
      switch (item.state) {
        case 'DISARMED':
          setBClass('Disarmed-device Pointer');
          break;
        case 'ARMING_IN_PROGRESS':
          setBClass('Armed-device');
          break;
        case 'ARMED':
          setBClass('Armed-device Pointer');
          break;
        case 'DISARMING_IN_PROGRESS':
          setBClass('Disarmed-device');
          break;
        default:
          setBClass('Undefined-device');
          break;
      }
    } else {
      setBClass('Undefined-device');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setMessage('');
      }, 1600);
    }
  }, [message]);

  const armDeviceClicked = (desiredState) => {
    if (
      (desiredState === 'ARM' && item.state === 'ARMED') ||
      (desiredState === 'DISARM' && item.state === 'DISARMED')
    ) {
      setMessage(desiredState === 'ARM' ? t('home.alreadyArmed') : t('home.alreadyDisarmed'));
      return;
    }
    const req = { arm: desiredState === 'ARM' };
    dispatch(armDevice(item.areaId, req, armingResult));
  };

  const messageContent = () => <div className="modal-header">{message}</div>;

  return (
    <Popup
      trigger={
        <button type="button" className={bClass}>
          <ButtonElement alt="button-element" />
        </button>
      }
      modal
      closeOnDocumentClick
    >
      {(close) => (
        <div className="modal-arming">
          {message ? (
            messageContent()
          ) : (
            <StateButtonContent action={close} armDeviceClicked={armDeviceClicked} />
          )}
        </div>
      )}
    </Popup>
  );
};

export default SelectStateButton;
