const useSiteId = ({ worksites }) => {
  const getDefaultSiteId = () => (worksites.length > 0 ? worksites[0].siteId : '');

  const doesSiteIdExist = (id) => worksites?.some((el) => el.siteId === id);

  return {
    getDefaultSiteId,
    doesSiteIdExist,
  };
};

export default useSiteId;
