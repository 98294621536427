/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Timeline from '../components/Timeline';
import ArmingDashboard from '../components/ArmingDashboard';
import Calendar from '../components/Calendar';
import { updateWorksites, setWorksite } from '../actions/sites';
import { getUserSubscription, isPushNotificationSupported } from '../push';
import { fetchFavourites } from '../actions/favourite';
import HomePreview from '../components/HomePreview';
import useSiteId from '../hooks/useSiteId';
import PageLoader from '../components/PageLoader';
import ErrorHandler from '../components/ErrorHandler';
import QuickInfo from '../components/QuickInfo';
import GWServicePreview from '../components/GWServicePreview';
import LightServicePreview from '../components/LightServicePreview';
import registerFCMPushNotifications from '../components/NativePush';
import LoadingCircle from '../elements/LoadingCircle';

function Home() {
  const navigate = useNavigate();
  const pushNotificationSupported = isPushNotificationSupported();
  const { i18n } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const language = auth.user?.user?.language;
  const changeLanguage = () => {
    i18n.changeLanguage(language);
  };
  const { siteId } = useParams();
  const worksites = useSelector((state) => state.worksiteList.worksites);
  const worksiteList = useSelector((state) => state.worksiteList);
  const [currentSite] = worksites?.filter((i) => i.siteId === siteId ?? undefined) ?? [];
  const currentWorksiteId = useSelector((state) => state.siteId);
  const favourites = useSelector((state) => state?.favouritesReducer?.favourites?.favourites);

  const dispatch = useDispatch();

  const [calendarDate, setCalendarDate] = useState();
  const { getDefaultSiteId, doesSiteIdExist } = useSiteId({ worksites });
  const hasFCMPushNotifications = useRef(false);

  useEffect(() => {
    if (auth.user) {
      dispatch(updateWorksites());
      dispatch(setWorksite(siteId));
      dispatch(fetchFavourites());
    }
  }, [dispatch, siteId, auth.user]);

  /**
   * 2023-09-17
   * Fix for the bug where the Cloo-icon in nav-bar links to an object,
   * and customers cannot reach their worksite due to redux issues (?)
   */

  /**
   * 2023-09-18
   * Issue:
   * If a user navigates to a non-existing siteId, the app would display a blank screen.
   * Fix:
   * We check if user is on non-existing worksite by checking if currentWorksiteId is empty
   * and currentSite is undefined.
   * If user is on non-existing worksite, we redirect user to first site in the list.
   *
   * Note:
   * Since props are not loaded imidiately after user logs in,
   * we are using setTimeOut to wait for props to load.
   * We should look into better way for handling this.
   */

  // avoid get redeclared on every render
  let updateTimeOut = useRef(null);

  const updateSiteId = (newSiteId) => {
    clearTimeout(updateTimeOut.current);

    updateTimeOut = setTimeout(() => {
      setWorksite(newSiteId);
      navigate(`/site/${newSiteId}`, { replace: true });
    }, 100);
  };

  /**
   * 2023-09-20
   * Issue:
   * useEffect updates the siteId when it's different from currentWorksiteId.
   * After updating, it causes a re-render of the component. On this render,
   * the useEffect checks again and finds that siteId and currentWorksiteId are not the same,
   * leading to another update. This creates an endless loop of updates and re-renders.
   * Solution:
   * Moved it to new useEffect hook where it won't get triggered by all different dependencies
   * so it won't get re-rendered.
   */
  useEffect(() => {
    if (worksiteList.isLoaded && siteId !== currentWorksiteId && doesSiteIdExist(siteId)) {
      updateSiteId(siteId);
    }
  }, [worksiteList.isLoaded]);

  useEffect(() => {
    if (!worksiteList.isLoaded) return;
    if (!siteId && !currentWorksiteId) {
      updateSiteId(getDefaultSiteId());
      return;
    }
    if ((!siteId || siteId === undefined) && currentWorksiteId) {
      updateSiteId(currentWorksiteId);
      return;
    }
    if (siteId !== currentWorksiteId && !doesSiteIdExist(siteId)) {
      updateSiteId(getDefaultSiteId());
      return;
    }

    // If everything seems broken navigate to the default site.
    setTimeout(() => {
      if (!currentWorksiteId && !siteId) {
        navigate('/site/');
      }
    }, 3000);
  }, [worksiteList.isLoaded, currentWorksiteId, siteId]);

  useEffect(() => {
    if (auth.isLoggedIn && !worksiteList?.isLoaded) {
      updateWorksites();
    }

    if (language) {
      changeLanguage(language);
    }

    if (auth.isLoggedIn && pushNotificationSupported) {
      getUserSubscription();
    }

    /* Register FCM push messages */
    if (auth.isLoggedIn && !hasFCMPushNotifications.current) {
      registerFCMPushNotifications();
      hasFCMPushNotifications.current = true;
    }
    if (!auth.isLoggedIn) {
      hasFCMPushNotifications.current = false;
    }
  }, [auth.isLoggedIn, worksiteList.isLoaded, language, currentWorksiteId]);

  const handleDateClick = (clickedDate) => setCalendarDate(clickedDate);

  /**
   * 2023-09-17
   * Fix for the bug where the Cloo-icon in nav-bar links to an object,
   * and customers cannot reach their worksite due to redux issues (?)
   */
  if (typeof currentWorksiteId !== 'string' || currentWorksiteId.length < 2) {
    // Wait for sites to update and update currentWorkSite
    return (
      <div className="Center">
        <PageLoader />
      </div>
    );
  }

  if (!currentSite) return <LoadingCircle />;

  return (
    <main>
      <ErrorHandler>
        <ArmingDashboard currentWorksiteId={currentWorksiteId} currentSite={currentSite} />
      </ErrorHandler>
      <QuickInfo currentSite={currentSite} />
      <div className="Home-preview-container">
        <GWServicePreview currentSite={currentSite} favourites={favourites} />
        <LightServicePreview currentSite={currentSite} favourites={favourites} />
      </div>
      <HomePreview currentSite={currentSite} favourites={favourites} />
      <Calendar handleDateClick={handleDateClick} />
      <Timeline calendarDate={calendarDate} />
    </main>
  );
}

Home.defaultProps = {
  worksiteList: {},
};

Home.propTypes = {
  worksiteList: PropTypes.shape({
    isLoaded: PropTypes.bool,
    worksites: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any]))),
  }),
};

export default Home;
