import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import SocketProvider from './services/sockets/context';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store/store';
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <SocketProvider>
      <Suspense fallback={<div>Loading...</div>}>
        <App store={store} />
      </Suspense>
    </SocketProvider>
  </Provider>,
);

navigator.serviceWorker.register('/sw.js', {
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event) => {
        if (event.target.state === 'activated') {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
});

reportWebVitals();
