import { SET_SITE_INFO, CLEAR_SITE_INFO } from '../actions/types';

export default function siteInfo(state = [], action) {
  const { type, payload } = action;

  switch (type) {
    case SET_SITE_INFO:
      if (Array.isArray(state)) {
        if (Array.isArray(payload.siteInfo)) {
          return state.concat(payload.siteInfo);
        }
        return state;
      }
      return [];
    case CLEAR_SITE_INFO:
      return [];
    default:
      return state;
  }
}
