import React from 'react';
import ErrorHandler from './ErrorHandler';
import GWServiceItem from './GWServiceItem';

const GWServicePreview = ({ currentSite, favourites }) => {
  const services = currentSite?.services?.filter((obj) => obj.type === 'gateway');

  const favouriteService = services?.filter((s) => favourites?.includes(s.externalId));

  const servicesToRender = favouriteService?.length > 0 ? favouriteService : services;

  if (!servicesToRender || servicesToRender.length === 0) return null;

  return (
    <ErrorHandler>
      {servicesToRender.map((service) => (
        <GWServiceItem key={service.externalId} item={service} />
      ))}
    </ErrorHandler>
  );
};

export default GWServicePreview;
