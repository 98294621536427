import React from 'react';
import { Oval } from 'react-loader-spinner';

function PageLoader() {
  return (
    <div className="Center-container">
      <Oval
        wrapperStyle={{
          margin: '32px 0',
        }}
        arialLabel="loading-indicator"
        height={100}
        width={100}
        strokeWidth={1}
        color="#35485a"
        secondaryColor="#35485a"
      />
    </div>
  );
}

export default PageLoader;
