import React from 'react';

// eslint-disable-next-line react/function-component-definition
const CameraPlayer = ({ url, hideLoader, height }) => (
  <iframe
    data-testid="camera-player-iframe"
    className="Camera-box__video"
    src={url}
    onLoad={hideLoader || undefined}
    loading="eager"
    height={height || '360px'}
    frameBorder="0"
    scrolling="no"
    allow="autoplay; encrypted-media; playsInline"
    allowFullScreen
    title="video"
  />
);

export default CameraPlayer;
