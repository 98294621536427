const stateTypes = {
  UNDEFINED: 'UNDEFINED',
  TRANSITION: 'TRANSITION',
  DISARMED: 'DISARMED',
  ARMED: 'ARMED',
  MIXED: 'MIXED',
};

const devices = {
  camera: 'camera',
  siren: 'siren',
  gateway: 'gateway',
  pir: 'pir',
  sensor: 'sensor',
  notype: 'notype',
  fire: 'fire',
};

// Note: Needs unit testing
const withinTime = (t, min, sec) => {
  const now = new Date().getTime();
  const time = Date.parse(t);
  const limit = min * sec * 1000;
  if (now < time + limit) return true;
  return false;
};

/**
 *
 * Makes calculations for the states
 *
 * Returns an array of zones that fulfils the criteria state & device
 * @param {*} states
 * @param {*} state
 * @param {*} device
 *
 */

// Note: Needs unit testing
function calcArm(states, state, device) {
  return Object.keys(states)
    .filter((key) => states[key].state === state && states[key].type === device)
    .map((key) => states[key]);
}

// Note: Needs unit testing
const calcStates = (states, installer) => {
  let armedCamera = [];
  let disarmedCamera = [];
  const transition = [];
  let armedSiren = [];
  let disarmedSiren = [];
  let gateway = [];
  let armedPir = [];
  let disarmedPir = [];
  let armedSensor = [];
  let disarmedSensor = [];
  let armedNotype = [];
  let disarmedNotype = [];
  let armedFire = [];
  let disarmedFire = [];
  const inprogress = [];

  function calcTransition(state, device, withinTimeArr, updatedArr) {
    const transitionMin = 1;
    const transitionSec = 5;
    Object.keys(states)
      .filter((key) => states[key].state === state)
      .filter((key) => {
        if (
          states[key].type === device &&
          withinTime(states[key].updated, transitionMin, transitionSec)
        ) {
          withinTimeArr.push(states[key]);
        } else if (
          states[key].type === device &&
          !withinTime(states[key].updated, transitionMin, transitionSec)
        ) {
          updatedArr.push(states[key]);
          inprogress.push(states[key]);
        }
        return false;
      });
  }

  function calcTransitionUsers(state, device, withinTimeArr, updatedArr, fallbackArr) {
    const transitionMin = 1;
    const transitionSec = 5;
    const updateMin = 5;
    const updateSec = 60;
    Object.keys(states)
      .filter((key) => states[key].state === state)
      .filter((key) => states[key].type === device)
      .filter((key) => {
        if (withinTime(states[key].updated, transitionMin, transitionSec)) {
          withinTimeArr.push(states[key]);
        } else if (
          !withinTime(states[key].updated, transitionMin, transitionSec) &&
          withinTime(states[key].updated, updateMin, updateSec)
        ) {
          updatedArr.push(states[key]);
        } else {
          fallbackArr.push(states[key]);
          inprogress.push(states[key]);
        }
        return false;
      });
  }

  if (typeof states === 'object') {
    disarmedCamera = calcArm(states, 'DISARMED', 'camera');
    armedCamera = calcArm(states, 'ARMED', 'camera');
    armedSiren = calcArm(states, 'ARMED', 'siren');
    disarmedSiren = calcArm(states, 'DISARMED', 'siren');
    armedPir = calcArm(states, 'ARMED', 'pir');
    disarmedPir = calcArm(states, 'DISARMED', 'pir');
    armedSensor = calcArm(states, 'ARMED', 'sensor');
    disarmedSensor = calcArm(states, 'DISARMED', 'sensor');
    armedNotype = calcArm(states, 'ARMED', undefined);
    disarmedNotype = calcArm(states, 'DISARMED', undefined);
    gateway = calcArm(states, 'ARMED', 'gateway').concat(calcArm(states, 'DISARMED', 'gateway'));
    armedFire = calcArm(states, 'ARMED', 'fire');
    disarmedFire = calcArm(states, 'DISARMED', 'fire');

    if (!installer) {
      calcTransitionUsers(
        'ARMING_IN_PROGRESS',
        devices.camera,
        transition,
        armedCamera,
        disarmedCamera,
      );
      calcTransitionUsers(
        'DISARMING_IN_PROGRESS',
        devices.camera,
        transition,
        disarmedCamera,
        armedCamera,
      );
      calcTransitionUsers(
        'ARMING_IN_PROGRESS',
        devices.sensor,
        transition,
        armedSensor,
        disarmedSensor,
      );
      calcTransitionUsers(
        'DISARMING_IN_PROGRESS',
        devices.sensor,
        transition,
        disarmedSensor,
        armedSensor,
      );
      calcTransitionUsers('ARMING_IN_PROGRESS', devices.pir, transition, armedPir, disarmedPir);
      calcTransitionUsers('DISARMING_IN_PROGRESS', devices.pir, transition, disarmedPir, armedPir);
      calcTransitionUsers(
        'ARMING_IN_PROGRESS',
        devices.siren,
        transition,
        armedSiren,
        disarmedSiren,
      );
      calcTransitionUsers(
        'DISARMING_IN_PROGRESS',
        devices.siren,
        transition,
        disarmedSiren,
        armedSiren,
      );
      calcTransitionUsers(
        'ARMING_IN_PROGRESS',
        undefined,
        disarmedNotype,
        armedNotype,
        disarmedNotype,
      );
      calcTransitionUsers(
        'DISARMING_IN_PROGRESS',
        undefined,
        armedNotype,
        disarmedNotype,
        armedNotype,
      );
      calcTransitionUsers('ARMING_IN_PROGRESS', devices.fire, transition, armedFire, disarmedFire);
      calcTransitionUsers(
        'DISARMING_IN_PROGRESS',
        devices.fire,
        transition,
        disarmedFire,
        armedFire,
      );
    } else {
      calcTransition('ARMING_IN_PROGRESS', devices.camera, transition, disarmedCamera);
      calcTransition('DISARMING_IN_PROGRESS', devices.camera, transition, armedCamera);
      calcTransition('ARMING_IN_PROGRESS', devices.sensor, transition, disarmedSensor);
      calcTransition('DISARMING_IN_PROGRESS', devices.sensor, transition, armedSensor);
      calcTransition('ARMING_IN_PROGRESS', devices.pir, transition, disarmedPir);
      calcTransition('DISARMING_IN_PROGRESS', devices.pir, transition, armedPir);
      calcTransition('ARMING_IN_PROGRESS', devices.siren, transition, disarmedSiren);
      calcTransition('DISARMING_IN_PROGRESS', devices.siren, transition, armedSiren);
      calcTransition('ARMING_IN_PROGRESS', undefined, armedNotype, disarmedNotype);
      calcTransition('DISARMING_IN_PROGRESS', undefined, disarmedNotype, armedNotype);
      calcTransition('ARMING_IN_PROGRESS', devices.fire, transition, disarmedFire);
      calcTransition('DISARMING_IN_PROGRESS', devices.fire, transition, armedFire);
    }
  }

  let currentState = stateTypes.UNDEFINED;

  const totalStates =
    armedCamera.length +
    armedPir.length +
    armedSensor.length +
    disarmedCamera.length +
    disarmedSensor.length +
    disarmedPir.length +
    transition.length +
    inprogress.length;

  if (totalStates > 0) {
    currentState = stateTypes.MIXED;

    if (transition.length > 0) {
      currentState = stateTypes.TRANSITION;
    } else if (armedCamera.length + armedPir.length + armedSensor.length === totalStates) {
      currentState = stateTypes.ARMED;
    } else if (disarmedCamera.length + disarmedPir.length + disarmedSensor.length === totalStates) {
      currentState = stateTypes.DISARMED;
    } else if (
      armedCamera.length + inprogress.length + armedPir.length + armedSensor.length ===
      totalStates
    ) {
      currentState = stateTypes.ARMED;
    } else if (
      disarmedCamera.length + inprogress.length + disarmedPir.length + disarmedSensor.length ===
      totalStates
    ) {
      currentState = stateTypes.DISARMED;
    }
  }

  return {
    currentState,
    armedCamera,
    armedSiren,
    armedPir,
    armedSensor,
    armedNotype,
    armedFire,
    disarmedCamera,
    disarmedSiren,
    disarmedPir,
    disarmedSensor,
    disarmedNotype,
    disarmedFire,
    gateway,
    transition,
    inprogress,
  };
};

/**
 * Sorting states of either
 *  - states Object of each state
 *  - states Object with array of grouped states; transition, armedCamera, ...
 * @param {Object} states
 * @returns Array<states>
 */

const sortStates = (states) =>
  Object.values(states)
    .flat()
    .filter((el) => typeof el === 'object')
    .sort((a, b) => {
      const aZone = (a.siaZone || a.areaId || 'zzzzz').toString();
      const bZone = (b.siaZone || b.areaId || 'zzzzx').toString();
      const isNumeric = (str) => /^\d+$/.test(str);

      const aZoneIsNumeric = isNumeric(aZone);
      const bZoneIsNumeric = isNumeric(bZone);

      if (aZoneIsNumeric && bZoneIsNumeric) {
        return parseInt(aZone, 10) - parseInt(bZone, 10);
      }

      if (aZoneIsNumeric) return -1;
      if (bZoneIsNumeric) return 1;

      if (aZone < bZone) return -1;
      if (aZone > bZone) return 1;

      return 0;
    })
    .filter((el) => el.areaId);

module.exports = {
  calcStates,
  stateTypes,
  withinTime,
  sortStates,
};
