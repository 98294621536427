import React, { forwardRef } from 'react';
import colors from '../helpers/colors.json';

const ToggleSwitch = forwardRef(
  ({ checked, handleChange, label, disabled, siteId, type, useDarkInactive }, ref) => {
    let borderColor;

    if (checked) {
      borderColor = 'var(--secondary)';
    } else if (useDarkInactive) {
      borderColor = colors.text;
    } else {
      borderColor = colors.mediumText;
    }
    const toggleSwitchClass = `toggle-switch ${useDarkInactive ? 'dark-inactive' : ''}`;

    return (
      <label
        className={toggleSwitchClass}
        htmlFor={`toggle-switch-${siteId}-${type}`}
        style={{ border: `1px solid ${borderColor}` }}
      >
        <input
          ref={ref}
          id={`toggle-switch-${siteId}-${type}`}
          type="checkbox"
          checked={checked}
          onChange={handleChange}
          aria-label={label}
          disabled={disabled}
        />
        <span className="switch" />
      </label>
    );
  },
);

export default ToggleSwitch;
