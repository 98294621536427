import React, { useState, useEffect } from 'react';

const PageScrolled = ({ children }) => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    const options = { passive: true };
    window.addEventListener('scroll', onScroll, options);
    return () => window.removeEventListener('scroll', onScroll, options);
  }, []);

  return <div className={scrolled ? 'scrolled' : ''}>{children}</div>;
};

export default PageScrolled;
