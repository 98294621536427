import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import HomePreviewTitle from '../../helpers/HomePreviewTitle';
import ArrowClick from '../../helpers/ArrowClick';
import CameraPlayerWrapper from './CameraPlayerWrapper';

function CameraFeedPreview({ data, mode }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cameraFeeds, setCameraFeeds] = useState([]);
  const siteId = useSelector((state) => state?.siteId);

  useEffect(() => {
    if (data.length === 0) {
      setCameraFeeds([]);
      return;
    }
    if (mode === 'favourites') {
      setCameraFeeds(data);
    } else {
      setCameraFeeds([data[currentIndex]]);
    }
  }, [siteId, data, mode, currentIndex]);

  const handleLeftClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : cameraFeeds.length - 1));
  };

  const handleRightClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % cameraFeeds.length);
  };

  if (!cameraFeeds || cameraFeeds.length === 0) {
    return null;
  }

  return (
    <>
      {cameraFeeds.map((cameraFeed) => (
        <div key={cameraFeed.externalId}>
          <HomePreviewTitle>Live camera</HomePreviewTitle>
          <div className="Camera-box__size" style={{ height: '420px' }}>
            <CameraPlayerWrapper type={cameraFeed.type} url={cameraFeed.url} />
          </div>
          {mode !== 'favourites' && cameraFeeds.length > 0 && (
            <ArrowClick leftClick={handleLeftClick} rightClick={handleRightClick} />
          )}
        </div>
      ))}
    </>
  );
}

export default CameraFeedPreview;
