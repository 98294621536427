/* eslint-disable consistent-return */
import React from 'react';
import { ReactComponent as Camera } from '../images/camera.svg';
import { ReactComponent as Transition } from '../images/transition.svg';
import { ReactComponent as Siren } from '../images/siren.svg';
import { ReactComponent as Gateway } from '../images/gateway.svg';
import { ReactComponent as Pir } from '../images/running-person.svg';
import { ReactComponent as Sensor } from '../images/sensor.svg';
import { ReactComponent as Notype } from '../images/no-type.svg';
import { ReactComponent as Fire } from '../images/fire.svg';
import colors from '../helpers/colors.json';

function DeviceIcon(props) {
  const device = (props.device || 'camera').toLowerCase();
  const state = (props.state || '').toUpperCase();

  const iconColor = (colorState) => {
    switch (colorState) {
      case 'ARMED':
        return colors.primary;
      case 'DISARMED':
        return colors.secondary;
      default:
        return colors.darkerText;
    }
  };

  const defineIcon = () => {
    if (
      state === 'DISARMING_IN_PROGRESS' ||
      state === 'ARMING_IN_PROGRESS' ||
      state === 'TRANSITION'
    ) {
      return <Transition />;
    }

    switch (device) {
      case 'gateway':
        return <Gateway fill={iconColor(state)} />;
      case 'siren':
        return <Siren fill={iconColor(state)} />;
      case 'pir':
        return <Pir fill={iconColor(state)} />;
      case 'sensor':
        return <Sensor fill={iconColor(state)} />;
      case 'notype':
        return <Notype fill={iconColor(state)} />;
      case 'camera':
        return <Camera fill={iconColor(state)} />;
      case 'fire':
        return <Fire fill={iconColor(state)} />;
      default:
        break;
    }
  };
  return <>{defineIcon()}</>;
}

export default DeviceIcon;
