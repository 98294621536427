import React from 'react';
import Plot from 'react-plotly.js';
import colors from '../../helpers/colors.json';

const SensorChart = ({ plotData, isHomePreview }) => {
  const dateArray = plotData.data.map((item) => item.datetime);
  const name = plotData.meta?.name;

  const allKeys = [...new Set(plotData.data.flatMap(Object.keys))].filter(
    (k) => k.startsWith('rh') || k.startsWith('temp'),
  );

  const predefinedColors = [
    '#FF5152',
    '#16205B',
    '#00B582',
    '#67B1F3',
    '#FFB800',
    'rgb(128, 131, 174)',
  ];

  const plotDataArray = allKeys.map((key, index) => {
    const yData = plotData.data.map((item) => item[key]);
    return {
      x: dateArray,
      y: yData,
      type: 'scatter',
      mode: 'lines',
      name: key,
      yaxis: key.startsWith('rh') ? 'y' : 'y2',
      line: {
        color: index < predefinedColors.length && predefinedColors[index],
      },
    };
  });

  return (
    <div className="Main-content">
      <div style={{ textAlign: 'center' }}>
        <Plot
          data={plotDataArray}
          style={{
            height: isHomePreview ? '450px' : '300px',
            minWidth: '280px',
          }}
          layout={{
            autosize: true,
            plot_bgcolor: 'rgba(0,0,0,0)',
            margin: { l: 50, r: 50, t: 60, b: 50 },
            paper_bgcolor: isHomePreview ? colors.lightText : colors.transparent,
            hovermode: 'x',
            font: {
              color: '#16205B',
            },
            title: {
              text: isHomePreview && name,
            },
            xaxis: {
              gridcolor: colors.transparent,
              type: 'date',
              tickformat: '%b %d',
              hoverformat: '%b %d, %Y %H:%M:%S',
            },
            yaxis: {
              gridcolor: colors.transparent,
              title: 'RH Readings',
              side: 'left',
            },
            yaxis2: {
              gridcolor: colors.mediumText,
              title: 'Temperature Readings',
              side: 'right',
              overlaying: 'y',
            },
            legend: {
              orientation: 'h',
              y: -0.5,
              x: 0.5,
              xanchor: 'center',
              yanchor: 'bottom',
            },
          }}
          useResizeHandler={true}
          config={{ responsive: true }}
        />
      </div>
    </div>
  );
};

export default SensorChart;
