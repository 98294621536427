import React from 'react';
import { ReactComponent as LightOff } from '../images/light-off.svg';
import { ReactComponent as LightOn } from '../images/light-on.svg';
import { ReactComponent as ClosedLock } from '../images/lock-closed.svg';
import { ReactComponent as OpenedLock } from '../images/lock-opened.svg';

const ToggleButton = ({ isOpen, onClick, type }) => {
  const isLight = type === 'light';

  const getIcon = () => {
    if (type === 'light') {
      return isOpen ? <LightOn fill="var(--secondary)" /> : <LightOff />;
    }
    return isOpen ? <OpenedLock fill="var(--primary)" /> : <ClosedLock />;
  };

  return (
    <button
      type="button"
      aria-label={isLight ? 'Toggle light' : 'Toggle lock'}
      className="Reset-button-styles Button-icon"
      onClick={onClick}
    >
      {getIcon()}
    </button>
  );
};

export default ToggleButton;
