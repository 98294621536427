import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import useDataFetcher from '../../hooks/useDataFetcher';
import fetchPlotData from '../../services/fetchPlotData';
import HeaterPreview from './HeaterPreview';

// eslint-disable-next-line react/function-component-definition
const HeaterPreviewFetch = ({ data, mode }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { siteId } = useParams();

  const fetchHeaterData = async (s) => {
    const heater = await fetchPlotData('eze', s.externalId, s.name, {
      from: s.fromTime,
      to: s.toTime,
    });

    return heater;
  };

  const { fetchedData, isError } = useDataFetcher(
    data,
    fetchHeaterData,
    mode,
    currentIndex,
    siteId,
  );

  const handleLeftClick = () => {
    setCurrentIndex((oldIndex) => (oldIndex - 1 + data.length) % data.length);
  };

  const handleRightClick = () => {
    setCurrentIndex((oldIndex) => (oldIndex + 1) % data.length);
  };

  if (fetchedData.length === 0) {
    return null;
  }

  return (
    <>
      {fetchedData?.map((heater) => (
        <HeaterPreview
          key={heater.meta?.externalId}
          heater={heater}
          mode={mode}
          fetchedData={fetchedData}
          handleLeftClick={handleLeftClick}
          handleRightClick={handleRightClick}
        />
      ))}
      {isError && <p>{isError}</p>}
    </>
  );
};

export default HeaterPreviewFetch;
