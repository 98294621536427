import backend from './backend';
import store from '../store/store';

const getEvents = () => {
  const siteId = store.getState().siteId;

  let to;
  try {
    const events = store.getState().recentEvents;
    const lastItem = events[events.length - 1];
    to = lastItem.time;
  } catch (err) {
    to = 9;
  }

  return backend.get(`/event/site/${siteId}?items=150&to=${to}`);
};

const getAlarmEvents = (magicToken) => backend.get(`/verify/${magicToken}`);
const getServiceEvents = (type) =>
  backend
    .get(`/event/service/${type}`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });

const getUserEvents = (email) => backend.get(`/event/user/${email}`);

const timelineservice = {
  getEvents,
  getAlarmEvents,
  getServiceEvents,
  getUserEvents,
};

export default timelineservice;
