/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Popup from 'reactjs-popup';
import { updateZone, updateWorksites } from '../actions/sites';
import { ReactComponent as Pencil } from '../images/pencil.svg';
import colors from '../helpers/colors.json';
import Button from '../elements/Button';

function SettingsZone({ item, action }) {
  const [editZone, setEditZone] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [failed, setFailed] = useState(false);
  const { t } = useTranslation();
  let valueZone;
  const change = (evt) => {
    valueZone = { value: evt?.target?.value };
  };
  const dispatch = useDispatch();
  const zone = item.siaZone || item.areaId || 'N/A';
  const name = item.name || 'N/A';

  const updateResult = (response, error) => {
    if (response) {
      dispatch(updateWorksites());
      setSubmit(true);
      setEditZone(false);
      setTimeout(() => {
        setSubmit(false);
      }, 2000);
    } else if (error) {
      setEditZone(false);
      setFailed(t('settings.updatefailed'));
      setTimeout(() => {
        setFailed(false);
      }, 3000);
    }
  };

  const submitUpdateZone = () => {
    let req;
    switch (action) {
      case 'updateSia':
        req = { siaZone: valueZone?.value };
        break;
      case 'updateName':
        req = { name: valueZone?.value };
        break;
      case 'selectState':
        req = valueZone?.value === 'Disarm' ? { state: 'DISARMED' } : { state: 'ARMED' };
        break;
      default:
        req = null;
    }
    if (req) {
      dispatch(updateZone(item.areaId, req, updateResult));
    }
  };

  const trigger = () => {
    if (action === 'updateSia') {
      return (
        <div
          className="Row-container-simple Pointer-cursor Row-container-right"
          onClick={() => setEditZone(!editZone)}
          onKeyDown={() => setEditZone(!editZone)}
        >
          <Pencil fill={colors.darkerText} className="Margin-rxs" />
          <p className="Sm-label-gr Bold-font">{zone}</p>
        </div>
      );
    }
    if (action === 'updateName') {
      return (
        <div
          className="Row-container-simple Pointer-cursor Row-container-right"
          onClick={() => setEditZone(!editZone)}
          onKeyDown={() => setEditZone(!editZone)}
        >
          <Pencil fill={colors.darkerText} className="Margin-rxs" />
          <p className="Sm-label-gr Bold-font">{name}</p>
        </div>
      );
    }
    return false;
  };

  const headerText = {
    updateSia: t('settings.siazone'),
    updateName: t('settings.namezone'),
  };

  const getHeaderText = (headerAction) => headerText[headerAction] || 'Default Header';

  const content = () => {
    if (action === 'updateSia') {
      return (
        <input
          type="number"
          className="Form-input Form-input-modal"
          onChange={change}
          placeholder={item.siaZone ? item.siaZone : t('settings.setsiazone')}
        />
      );
    }
    if (action === 'updateName') {
      return (
        <input
          type="text"
          className="Form-input Form-input-modal"
          onChange={change}
          placeholder={t('settings.name')}
        />
      );
    }
    return true;
  };

  return (
    <>
      {submit ? <span className="Success Margin-rs">{t('settings.updated')}</span> : null}
      {failed && <span className="Errors Margin-ls">{failed}</span>}
      <Popup trigger={trigger} modal nested>
        {(close) => (
          <div className="modal">
            <button className="close" onClick={close}>
              &times;
            </button>
            <h4 className="header">{getHeaderText(action)}</h4>
            <div className="content">{content()}</div>
            <div className="actions Row-container-right">
              <div className="Margin-rxs">
                <Button
                  className="Secondary-button"
                  size="small"
                  type="submit"
                  text={t('buttons.cancel')}
                  onClick={() => {
                    setEditZone(!editZone);
                    close();
                  }}
                />
              </div>
              <Button
                size="small"
                text={t('buttons.save')}
                onClick={() => {
                  submitUpdateZone();
                  close();
                }}
              />
            </div>
          </div>
        )}
      </Popup>
    </>
  );
}

export default SettingsZone;
