import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ToggleIconButton from '../elements/ToggleIconButton';
import worksiteservice from '../services/worksite.service';
import getItemName from '../helpers/getItemName';

const GWServiceItem = ({ item }) => {
  const [serviceState, setServiceState] = useState(false);
  const [failed, setFailed] = useState(false);
  const siteId = useSelector((state) => state?.siteId);
  const name = getItemName(item);

  const result = (response, error) => {
    if (error) {
      setFailed(true);
      setTimeout(() => {
        setFailed(false);
      }, 2000);
    }
    return response;
  };

  const handleSubmit = (externalId) => {
    const action = 'OPEN';
    setServiceState(true);
    setTimeout(() => {
      setServiceState(false);
    }, 3000);

    worksiteservice
      .updateExternalService({ operation: { action } }, siteId, 'gateway', externalId)
      .then((res) => {
        result(res, null);
      })
      .catch((err) => {
        result(null, err);
        setServiceState(false);
        return err;
      });
  };

  return (
    <div className="gateway-preview-content">
      <p>{name}</p>
      <ToggleIconButton
        isOpen={serviceState}
        onClick={() => handleSubmit(item.externalId)}
        type="gateway"
      />
      {failed && <p>Failed</p>}
    </div>
  );
};

export default GWServiceItem;
