/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { formatTime } from '../helpers/formatTime';
import optimalTime from '../helpers/optimalTime';
import userContext from '../context/userContext';

function ImageReceived({ item, cb }) {
  const { t } = useTranslation();
  const installer = useContext(userContext);
  const receivedTime = item.filter((el) => el.received);

  const exceededTime = receivedTime.filter(
    (el) =>
      optimalTime.timeDifferenceOptimal(
        el.time,
        el.received,
        // 3 min in milliseconds
        180000,
      ) === false,
  );

  const sortedDatesAsc = exceededTime
    .slice()
    .sort((a, b) => (a.received > b.received ? 1 : a.received < b.received ? -1 : 0));

  const unique = (value, index, self) => self.indexOf(value) === index;
  const timeList = sortedDatesAsc
    .map((el) => {
      const finaleventDate = formatTime(el.received);
      return finaleventDate.time;
    })
    .filter(unique)
    .join(', ');

  useEffect(() => {
    const highlightImages = exceededTime
      .map(({ imgUrl }) => imgUrl)
      .reverse()
      .filter((imgUrl) => imgUrl);
    if (highlightImages.length > 0) cb(highlightImages);
  }, []);

  if (receivedTime === undefined || !installer) {
    return null;
  }

  if (timeList.length > 0) {
    return (
      <div className="Text-align-right">
        <p className="Sm-label-gr">{t('event.received')}</p>
        <p className="Email">{timeList}</p>
      </div>
    );
  }
  return null;
}

export default ImageReceived;
