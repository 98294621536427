import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TextButton from '../elements/TextButton';

const truncate = (input) => (input.length > 25 ? `${input.substring(0, 25)}...` : input);

function MixedStateAlert({ currentState }) {
  const { t } = useTranslation();
  const [display, setDisplay] = useState(false);
  const [showTruncate, setShowTruncate] = useState(true);
  const content = t('notifications.mixedstate');

  const closeAlert = () => {
    localStorage.setItem('mixedAlert', 'Viewed');
    setDisplay(!display);
  };

  useEffect(() => {
    if (!localStorage.getItem('mixedAlert')) {
      setDisplay(true);
    }
  }, []);

  if (display && currentState === 'MIXED') {
    return (
      <div className="Alert">
        <button
          className="Reset-button-styles close"
          aria-label="Close"
          type="button"
          onClick={closeAlert}
        />
        <p className={`Small-text Paddings-xs ${showTruncate ? 'Fade' : null}`}>
          {showTruncate ? truncate(content) : content}
        </p>
        <div className="Margin-lxs Margin-up">
          <TextButton
            cb={() => setShowTruncate(!showTruncate)}
            text={showTruncate ? t('buttons.showmore') : t('buttons.showless')}
            grey
          />
        </div>
      </div>
    );
  }
  return null;
}

export default MixedStateAlert;
