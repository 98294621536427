import React from 'react';
import { ReactComponent as ArrowLeft } from '../images/arrow-left-blue.svg';

const ArrowClickLeft = ({ leftClick }) => (
  <button
    type="button"
    className="Reset-button-styles Pointer"
    aria-label="Arrow Left"
    onClick={leftClick}
  >
    <ArrowLeft style={{ padding: '1rem' }} />
  </button>
);

export default ArrowClickLeft;
