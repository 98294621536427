import React from 'react';
import DeviceIcon from '../components/DeviceIcon';
import colors from './colors.json';

const createDeviceElements = (states) =>
  Object.keys(states)
    .map((keyName) => {
      if (states[keyName]?.length > 0) {
        const deviceData = keyName.split(/(?=[A-Z])/);
        const iconState = deviceData[0];
        const iconDevice = deviceData.length > 1 ? deviceData[1] : '';
        return (
          <div className="Pair-container Row-container-simple-center" key={keyName}>
            <p style={{ color: colors.darkerText }}>{states[keyName].length}</p>
            <DeviceIcon state={iconState} device={iconDevice} />
          </div>
        );
      }
      return null;
    })
    .filter((element) => element !== null);

export default createDeviceElements;
