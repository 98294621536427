import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  SET_ACCESS_TOKEN,
  LOGIN_FAIL,
  LOGOUT,
  SETTINGS_SUCCESS,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  RESET_PASSWORD_KEY,
  CLEAR_WORKSITE_LIST,
  CLEAR_SETTING_SCHEDULE,
  CLEAR_USERS,
  CLEAR_EVENTS,
  CLEAR_SCHEDULE,
  CLEAR_ACCESS_TOKEN,
  CLEAR_SITE_ID,
} from './types';

import AuthService from '../services/auth.service';
import showError from './error';
import socketClient from '../services/sockets/socketio';

export const registeruser =
  (username, phone, notifications, email, key, password, callback) => (dispatch) =>
    AuthService.register(username, phone, notifications, email, key, password)
      .then((response) => {
        dispatch({
          type: REGISTER_SUCCESS,
        });
        callback(response, null);
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: REGISTER_FAIL,
        });
        return error;
      });

export const updateSession = (session) => (dispatch) => {
  if (typeof session !== 'string' || session.length < 4) return;

  AuthService.updateSession(session)
    .then((response) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: response?.data },
      });

      dispatch({
        type: SET_ACCESS_TOKEN,
        payload: { token: response?.data?.token },
      });
    })
    .catch((error) => {
      localStorage.setItem('session', '');
      dispatch({
        type: LOGIN_FAIL,
      });
      return error;
    });
};

export const login = (email, password, callback) => (dispatch) =>
  AuthService.login(email, password)
    .then((response) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: response?.data },
      });
      dispatch({
        type: SET_ACCESS_TOKEN,
        payload: { token: response?.data?.token },
      });

      const session = response?.data?.session;
      if (session) {
        localStorage.setItem('session', session);
        socketClient.startSocket(session);
      }

      callback(response, null);
    })
    .catch((error) => {
      callback(null, showError(error));
      dispatch({
        type: LOGIN_FAIL,
      });
    });

export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: CLEAR_ACCESS_TOKEN,
  });
  dispatch({
    type: LOGOUT,
  });
  dispatch({
    type: CLEAR_SITE_ID,
  });
  dispatch({
    type: CLEAR_WORKSITE_LIST,
  });
  dispatch({
    type: CLEAR_SETTING_SCHEDULE,
  });
  dispatch({
    type: CLEAR_EVENTS,
  });
  dispatch({
    type: CLEAR_USERS,
  });
  dispatch({
    type: CLEAR_SCHEDULE,
  });

  socketClient.endSocket();
};

export const updateProfile = (name, phone, language, email, callback) => (dispatch) => {
  AuthService.updateProfile(name, phone, language, email)
    .then((response) => {
      dispatch({
        type: SETTINGS_SUCCESS,
      });
      callback(response, null);
    })
    .catch((error) => {
      callback(null, error);
      return error;
    });
};

export const resetPassword = (newPassword, currentPassword, callback) => (dispatch) => {
  AuthService.resetPassword(newPassword, currentPassword)
    .then((response) => {
      dispatch({
        type: RESET_PASSWORD,
      });
      callback(response, null);
    })
    .catch((error) => {
      callback(null, showError(error));
      return error;
    });
};

export const forgotPassword = (email, callback) => (dispatch) => {
  AuthService.forgotPassword(email)
    .then((response) => {
      dispatch({
        type: FORGOT_PASSWORD,
        payload: response.data,
      });
      if (response.data?.error === 'Hidden error') {
        callback(null, showError(response.data.error));
      } else callback(response, null);
    })
    .catch((error) => {
      callback(null, showError(error));
      return error;
    });
};

export const resetPasswordKey = (email, newPassword, key, callback) => (dispatch) => {
  AuthService.resetPasswordKey(email, newPassword, key)
    .then((response) => {
      dispatch({
        type: RESET_PASSWORD_KEY,
      });
      callback(response, null);
    })
    .catch((err) => {
      callback(null, err.response.data.error || err);
      return err;
    });
};
