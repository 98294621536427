import { ADD_EVENTS, CLEAR_EVENTS } from '../actions/types';

export default function recentEvents(state = [], action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_EVENTS:
      if (Array.isArray(state)) {
        if (Array.isArray(payload.events)) {
          const updatedEvents = payload.events.map((event) => ({
            ...event,
            uuid: crypto.randomUUID(),
          }));
          return state.concat(updatedEvents);
        }
        return state;
      }
      return [];
    case CLEAR_EVENTS:
      return [];
    default:
      return state;
  }
}
