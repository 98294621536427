function formatTime(initialDate, params, dstAdjustment = 0.0) {
  const options = params || {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    minimumFractionDigits: 2,
  };

  const finalDate = new Date(new Date(initialDate).getTime() + dstAdjustment * 3600 * 1000);
  const date = finalDate.toLocaleDateString('sv-SE', 'numeric');
  const time = finalDate.toLocaleTimeString('sv-SE', options);
  return { date, time };
}

function convertTimeToDecimal(timeString) {
  const [hours, minutes] = timeString.split(':').map(Number);
  return hours + minutes / 60;
}

export { formatTime, convertTimeToDecimal };
