import { GET_SCHEDULE, PUT_SCHEDULE, DELETE_SCHEDULE, CLEAR_SCHEDULE } from './types';

import showError from './error';
import scheduleservice from '../services/schedule.service';

export const createSchedule =
  (data, siteId, callback, scheduleActivated, scheduleDeactivated) => (dispatch) => {
    scheduleservice
      .createSchedule(data, siteId, scheduleActivated, scheduleDeactivated)
      .then((response) => {
        dispatch({
          type: PUT_SCHEDULE,
          payload: data,
        });
        callback(response, null);
      })
      .catch((error) => {
        callback(null, showError(error));
        return error;
      });
  };

export const getSchedule = () => (dispatch) => {
  scheduleservice
    .getSchedule()
    .then((response) => {
      dispatch({
        type: GET_SCHEDULE,
        payload: response.data,
      });
    })
    .catch(() => {});
};

export const clearSchedule = () => (dispatch) => {
  dispatch({
    type: CLEAR_SCHEDULE,
  });
};

export const deleteSchedule = (siteId, callback) => (dispatch) => {
  scheduleservice
    .deleteSchedule(siteId)
    .then((response) => {
      dispatch({
        type: DELETE_SCHEDULE,
      });
      callback(response, null);
    })
    .catch((error) => {
      callback(null, showError(error));
      return error;
    });
};
