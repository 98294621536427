import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import worksiteservice from '../services/worksite.service';
import ToggleIconButton from '../elements/ToggleIconButton';
import { updateWorksites } from '../actions/sites';
import getItemName from '../helpers/getItemName';

const LightServiceItem = ({ item, externalId, lightState }) => {
  const [failed, setFailed] = useState(false);
  const siteId = useSelector((state) => state?.siteId);
  const dispatch = useDispatch();
  const name = getItemName(item);

  const [toggleLight, setToggleLight] = useState(false);

  useEffect(() => {
    setToggleLight(lightState);
  }, [lightState]);

  const result = (response, error) => {
    if (error) {
      setFailed(true);
      setTimeout(() => {
        setFailed(false);
      }, 2000);
    }
    return response;
  };

  const handleSubmit = (id) => {
    const action = toggleLight === 'ON' ? 'OFF' : 'ON';
    worksiteservice
      .updateExternalService({ operation: { action } }, siteId, 'light', id)
      .then((res) => {
        dispatch(updateWorksites());
        setToggleLight(action);
        result(res, null);
      })
      .catch((err) => {
        result(null, err);
        return err;
      });
  };

  return (
    <div key={externalId} className="light-preview-content">
      <p>{name}</p>
      <ToggleIconButton
        isOpen={toggleLight === 'ON'}
        onClick={() => handleSubmit(externalId)}
        type="light"
      />
      {failed && <p>Failed</p>}
    </div>
  );
};

export default LightServiceItem;
