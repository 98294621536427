import { useState, useEffect } from 'react';

const useDataFetcher = (data, fetchDataFunction, mode, currentIndex, siteId) => {
  const [fetchedData, setFetchedData] = useState([]);
  const [isError, setIsError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (data.length === 0) {
        setFetchedData([]);
        return;
      }

      try {
        let result;
        if (mode === 'favourites') {
          const promises = data.map((service) => fetchDataFunction(service));
          result = await Promise.all(promises);
        } else {
          result = [await fetchDataFunction(data[currentIndex])];
        }
        setFetchedData(result);
      } catch (error) {
        setIsError(`There was a problem with fetching data: ${error}`);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, mode, currentIndex, siteId]);

  return { fetchedData, isError };
};

export default useDataFetcher;
