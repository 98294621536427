/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOnlineStatus } from '../hooks/useOnlineStatus';
import { ReactComponent as Offline } from '../images/offline.svg';
import Button from '../elements/Button';

function OfflineNotification() {
  const { t } = useTranslation();
  const isOnline = useOnlineStatus();
  const [showDescription, setShowDescription] = useState(true);
  const controlDescription = () => {
    setShowDescription(!showDescription);
  };

  if (isOnline) return null;

  return (
    <div className="Row-container-simple-center Divider">
      <div className="Width-fit Width-s Paddings-s">
        <div className="Row-container-simple">
          <h4
            className="Margin-rs"
            onClick={() => controlDescription()}
            onKeyDown={() => controlDescription()}
          >
            {t('notifications.offline')}
          </h4>
          <Offline />
        </div>
        {showDescription ? (
          <p className="Grey-text Padding-ts">{t('notifications.offlinecheck')}</p>
        ) : null}
        {showDescription ? (
          <div className="Row-container-right Paddings-vs">
            <Button size="small" text={t('buttons.close')} onClick={controlDescription} />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default OfflineNotification;
