import React from 'react';

function TextButton(prop) {
  let cb = () => {};
  if (prop.cb) cb = prop.cb;

  let cbArgs = {};
  if (prop.cbArgs) cbArgs = prop.cbArgs;
  return (
    <div>
      <button
        type="button"
        className={prop.grey ? 'Text-button Grey-button' : 'Text-button'}
        onClick={() => cb(cbArgs)}
      >
        {prop.text}
      </button>
    </div>
  );
}

export default TextButton;
