import React, { useContext, useState } from 'react';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import userContext from '../context/userContext';
import worksiteservice from '../services/worksite.service';
import ErrorHandler from './ErrorHandler';
import QuickInfoItem from './QuickInfoItem';

const QuickInfo = ({ currentSite }) => {
  const installer = useContext(userContext);
  const [toggleFailed, setToggleFailed] = useState('');
  const { t } = useTranslation();

  const siteId = currentSite?.siteId;

  const fetcher = (type) =>
    worksiteservice.getService({ service: type }).then((response) =>
      response.data
        .filter((el) => el.siteId === siteId)
        .map((item) => ({
          ...item,
          active: item.active === undefined ? true : item.active,
        })),
    );

  const {
    data: rapidData,
    error: rapidError,
    mutate: mutateRapid,
  } = useSWR(siteId ? ['rapid', siteId] : null, () => fetcher('rapid'), {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  });

  const {
    data: tempestData,
    error: tempestError,
    mutate: mutateTempest,
  } = useSWR(siteId ? ['tempest', siteId] : null, () => fetcher('tempest'), {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  });

  const toggleState = async (currentSiteId, previousState, externalId, type) => {
    const data = {
      type,
      active: !previousState,
      externalId,
    };

    try {
      await worksiteservice.updateExternal(currentSiteId, data);

      const mutate = type === 'rapid' ? mutateRapid : mutateTempest;
      mutate(
        (currentData) =>
          currentData.map((item) =>
            item.externalId === externalId ? { ...item, active: !previousState } : item,
          ),
        false,
      );
    } catch (error) {
      setToggleFailed(`Failed to update: ${error.response?.data?.message || error.message}`);
    }
  };

  if (!installer) {
    return null;
  }

  if ((!rapidData || rapidData.length === 0) && (!tempestData || tempestData.length === 0)) {
    return null;
  }

  return (
    <ErrorHandler>
      <div className="Quick-info-container">
        {rapidData?.length > 0 &&
          rapidData.map((currentRapid) => (
            <QuickInfoItem
              key={currentRapid.externalId}
              item={currentRapid}
              onToggle={toggleState}
            />
          ))}
        {tempestData?.length > 0 &&
          tempestData.map((currentTempest) => (
            <QuickInfoItem
              key={currentTempest.externalId}
              item={currentTempest}
              onToggle={toggleState}
            />
          ))}
        {(rapidError || tempestError) && <p>{t('errors.errorLoadingData')}</p>}
        {toggleFailed && <p>{toggleFailed}</p>}
      </div>
    </ErrorHandler>
  );
};

export default QuickInfo;
