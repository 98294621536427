import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Oval } from 'react-loader-spinner';
import { ReactComponent as ButtonElement } from '../images/ArmingButtonElement.svg';
import Popup from './Popup';
import FallbackButton from './ArmingFallbackButton';
import Swipe from './Swipe';
import { stateTypes } from '../helpers/siteStates';
import { armWorksite } from '../actions/sites';
import StateDescription from './StateDescription';

function ArmingButton({ currentState, siteId, inprogress }) {
  const [failed, setFailed] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const armingResult = (err) => {
    if (err) setFailed(true);
    setTimeout(() => {
      setFailed(false);
    }, 3000);
  };

  const buttonClicked = () => {
    if (
      !(
        currentState === stateTypes.ARMED ||
        currentState === stateTypes.DISARMED ||
        currentState === stateTypes.MIXED
      )
    )
      return;
    const req = currentState === stateTypes.ARMED ? { arm: false } : { arm: true };
    dispatch(armWorksite(siteId, req, armingResult));
  };

  const button = () => {
    if (currentState === stateTypes.TRANSITION) {
      return (
        <Oval
          wrapperStyle={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '24px auto 48px',
          }}
          arialLabel="loading-indicator"
          height={100}
          width={100}
          strokeWidth={2}
          color="#e9d92c"
          secondaryColor="#e9d92c"
        />
      );
    }
    let bClass;

    let modalContent;
    switch (currentState) {
      case stateTypes.MIXED:
      case stateTypes.DISARMED:
        bClass = 'Disarmed-button Pointer';
        modalContent = t('modal.confirmarm');
        break;
      case stateTypes.ARMED:
        bClass = 'Armed-button Pointer';
        modalContent = t('modal.confirmdisarm');
        break;
      case stateTypes.UNDEFINED:
      default:
        bClass = 'Undefined-button';
        break;
    }
    if (bClass === 'Undefined-button')
      return (
        <button type="button" className={bClass} aria-label="Undefined-button">
          <img src={ButtonElement} alt="button-element" />
        </button>
      );
    return (
      <div className="Arming-button-wrapper">
        <Popup
          trigger={
            <button type="button" className={bClass} aria-label={bClass}>
              <ButtonElement alt="button-element" />
            </button>
          }
          action={buttonClicked}
          header={t('modal.confirmheader')}
          content={modalContent}
          btnCancel={t('buttons.nocancel')}
          btnAction={t('buttons.confirm')}
        />
        {currentState !== stateTypes.MIXED && (
          <StateDescription currentState={currentState} inprogress={inprogress} />
        )}
      </div>
    );
  };

  return (
    <div className="Width-xl">
      {currentState === stateTypes.MIXED ? (
        <Swipe
          slides={[
            button(),
            <FallbackButton
              currentState={currentState}
              stateTypes={stateTypes}
              currentWorksiteId={siteId}
              armingResult={armingResult}
            />,
          ]}
        />
      ) : (
        button()
      )}
      {failed && <p className="Errors">{t('settings.updatefailed')}</p>}
    </div>
  );
}

export default ArmingButton;
