import { SET_SITE_ID, CLEAR_SITE_ID } from '../actions/types';

export default function siteId(state = '', action) {
  const { type, payload } = action;

  switch (type) {
    case SET_SITE_ID:
      // console.log("setting siteId", {type,payload});
      return payload;

    case CLEAR_SITE_ID:
      return '';

    default:
      return state;
  }
}
