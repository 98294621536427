import React from 'react';
import { useTranslation } from 'react-i18next';

const StateButtonContent = ({ armDeviceClicked, action }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="modal-header">{t('home.doYouWantTo')}</div>
      <div className="modal-content">
        <button
          type="button"
          className="modal-content__arm"
          onClick={() => armDeviceClicked('ARM')}
        >
          {t('schedule.arm')}
        </button>
        <button
          type="button"
          className="modal-content__disarm"
          onClick={() => armDeviceClicked('DISARM')}
        >
          {t('schedule.disarm')}
        </button>
        <button type="button" onClick={action} className="modal-content__cancel">
          {t('buttons.cancel')}
        </button>
      </div>
    </>
  );
};

export default StateButtonContent;
