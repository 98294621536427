import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as Schedule } from '../images/Schedule.svg';
import { ReactComponent as Feed } from '../images/Video.svg';
import PageScrolled from './PageScrolled';

const SecurityTitle = ({ currentSite }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { siteId } = useParams();
  const url = `/site/${siteId}`;

  const securityIcons = [
    { Icon: Schedule, link: 'schedule' },
    { Icon: Feed, link: 'camera-feed' },
  ];

  const iconStyle = (isActive) => ({
    fill: isActive ? 'var(--primary)' : '#16205B',
    border: isActive ? '1px solid var(--primary)' : null,
  });

  return (
    <div className="Security-title">
      <PageScrolled>
        <div className="Row-container Align-center">
          <div className="Row-container Align-center Width-set Margins-vs">
            <h2>{currentSite?.siteName}</h2>
            <div className="Security-icon-wrapper">
              {securityIcons.map((item) => {
                const isActive = pathname === `${url}/${item.link}`;
                return (
                  <item.Icon
                    key={item.link}
                    style={iconStyle(isActive)}
                    className="Security-icon"
                    onClick={() => navigate(`${url}/${item.link}`)}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </PageScrolled>
    </div>
  );
};

export default SecurityTitle;
