import backend from './backend';

const fetchPlotData = async (type, externalId, name, metaData) => {
  try {
    const response = await backend.get(`/readings/${type}/${externalId}`, {
      params: metaData,
    });
    const { data, legend } = response.data;
    const newData = {
      meta: {
        externalId,
        name,
      },
      data,
      ...(legend && { legend }),
    };

    return newData;
  } catch (error) {
    const newData = {
      meta: {
        name: `${error.response?.data?.message || 'Unknown error'} (${externalId})`,
        valid: false,
      },
      values: [],
    };
    return newData;
  }
};

export default fetchPlotData;
