import React, { useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { formatTime } from '../helpers/formatTime';
import TrashButton from '../elements/TrashButton';
import BatteryLevel from './BatteryLevel';
import { deleteDevice } from '../actions/sites';
import Exclamation from '../images/exclamation-circle.svg';
import { withinTime } from '../helpers/siteStates';
import userContext from '../context/userContext';
import SettingsZone from './SettingsZone';
import privilegedRole from '../helpers/privilegedRole';
import DeviceIcon from './DeviceIcon';
import { ReactComponent as Screenshot } from '../images/Screenshot.svg';
import worksiteservice from '../services/worksite.service';
import SelectStateButton from './SelectStateButton';

function DeviceCard({ item }) {
  const installer = useContext(userContext);
  const currentWorksiteId = useSelector((state) => state.siteId);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const updated = formatTime(item.updated);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const zone = item.siaZone || item.areaId || 'N/A';

  const handleSnapshot = () => {
    if (currentWorksiteId && item.areaId !== undefined) {
      worksiteservice
        .takeSnapshot(currentWorksiteId, item.areaId)
        .then(() => {
          setSuccess(t('success.snapshotSuccess'));
          setTimeout(() => {
            setSuccess('');
          }, 1500);
        })
        .catch(() => {
          setError(t('errors.snapshotError'));
          setTimeout(() => {
            setError('');
          }, 1500);
        });
    }
  };

  const armingResult = (err) => {
    if (err === 'Data not found') {
      setError(t('errors.devicenotfound'));
    } else if (err) {
      setError(t('errors.devicearm'));
    }
  };

  const deleteDeviceClicked = () => {
    if (item.areaId !== undefined) {
      dispatch(deleteDevice(item.areaId));
    } else setError(t('errors.devicedelete'));
  };

  return (
    <div className="Row-container Paddings-vs Divider">
      <div className="Pair-container Row-container-simple">
        <div className="Row-container-simple Arm-device">
          <SelectStateButton item={item} armingResult={armingResult} />
        </div>
        <div>
          <div className="Row-container-simple">
            <div>
              <p className="Sm-label-gr">{t('device')}</p>
              <span className="Row-container-simple">
                <DeviceIcon state={item.state} device={item.type} />
                <p className="Sm-label-gr Margin-ls Bold-font">{item.type || 'no set type'}</p>
              </span>
            </div>
            {installer && (
              <div className="Margin-ls">
                <BatteryLevel battery={item.battery} />
              </div>
            )}
          </div>
          {installer && (
            <div>
              <p className="Sm-label-gr">{t('settings.updated')}</p>
              <p className="Sm-label-gr Bold-font">
                {updated.date} {updated.time}
              </p>
            </div>
          )}
          {(item.state === 'DISARMING_IN_PROGRESS' && withinTime(item.updated, 5, 60) === false) ||
          (item.state === 'ARMING_IN_PROGRESS' && withinTime(item.updated, 5, 60) === false) ? (
            <div>
              <p className="Sm-label-gr">{t('errors.deviceconnection')}</p>
              <span className="Row-container-simple">
                <img src={Exclamation} alt="device" style={{ fill: 'var(--primary)' }} />
                <p className="Sm-label-gr Margin-ls">{item.state || 'no set type'}</p>
              </span>
            </div>
          ) : null}
          {error && <p className="Errors">{error}</p>}
          {success && <p className="Success">{success}</p>}
        </div>
      </div>
      <div className="Column-container-right" style={{ justifyContent: 'space-around' }}>
        {installer && <TrashButton type="button" cb={deleteDeviceClicked} cbArgs={item.updated} />}
        <div className="Text-align-right">
          <p className="Sm-label-gr">{t('timeline.area')}</p>
          {installer && item.areaId ? (
            <SettingsZone item={item} action="updateSia" />
          ) : (
            <p className="Sm-label-gr Bold-font">{zone}</p>
          )}
        </div>
        <div className="Text-align-right">
          <p className="Sm-label-gr">{t('settings.name')}</p>
          {privilegedRole(currentWorksiteId) ? (
            <SettingsZone item={item} action="updateName" />
          ) : (
            <p className="Sm-label-gr Bold-font">{item.name || 'N/A'}</p>
          )}
        </div>
        {installer && item.type === 'camera' && !item.areaId.includes('-') && (
          <div style={{ marginTop: '3px' }}>
            <Screenshot onClick={handleSnapshot} />
          </div>
        )}
      </div>
    </div>
  );
}

export default DeviceCard;
