import {
  GET_SCHEDULE,
  PUT_SCHEDULE,
  CHANGE_SCHEDULE,
  DELETE_SCHEDULE,
  CLEAR_SCHEDULE,
} from '../actions/types';

const initialState = [];

export default function armingSchedule(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SCHEDULE:
    case PUT_SCHEDULE:
      return payload;
    case CHANGE_SCHEDULE:
      return payload;
    case DELETE_SCHEDULE:
    case CLEAR_SCHEDULE:
      return null;
    default:
      return state;
  }
}
