import React, { createContext, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import darkenColour from '../helpers/darkenColour';

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  const { worksites } = useSelector((state) => state.worksiteList);
  const siteId = useSelector((state) => state.siteId);
  const currentSite = worksites?.find((site) => site.siteId === siteId);

  useEffect(() => {
    const defaultPrimaryColor = '#ff5152';
    const defaultSecondaryColor = '#00b582';

    const primaryColor = currentSite?.theme?.primaryColor || defaultPrimaryColor;
    const secondaryColor = currentSite?.theme?.secondaryColor || defaultSecondaryColor;
    const darkerPrimaryColor = darkenColour(primaryColor, 10);

    document.documentElement.style.setProperty('--primary', primaryColor);
    document.documentElement.style.setProperty('--secondary', secondaryColor);
    document.documentElement.style.setProperty('--primary-dark', darkerPrimaryColor);
  }, [currentSite?.theme?.primaryColor, currentSite?.theme?.secondaryColor]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  return <ThemeContext.Provider value={{}}>{children}</ThemeContext.Provider>;
};
