import React from 'react';
import { Oval } from 'react-loader-spinner';

const LoadingCircle = () => (
  <Oval
    wrapperStyle={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '24px auto 48px',
    }}
    arialLabel="loading-indicator"
    height={100}
    width={100}
    strokeWidth={2}
    color="var(--primary)"
    secondaryColor="var(--primary)"
  />
);

export default LoadingCircle;
