import React, { useState } from 'react';
import { connect } from 'react-redux';
import CalendarView from './CalendarView';
import ErrorHandler from './ErrorHandler';

const Calendar = ({ recentEvents, handleDateClick }) => {
  const [currentDate, setCurrentDate] = useState(new Date());

  const eventArrays = recentEvents.map((eventArray) => eventArray[0]);
  const eventAlarm = eventArrays
    .filter((ev) => ev.event === 'TAMPER ALARM' || ev.event === 'BURGLAR ALARM')
    .map((ev) => ({
      event: ev.event,
      time: new Date(ev.time.substring(0, 10)),
    }));

  if (recentEvents.length === 0) {
    return null;
  }

  return (
    <ErrorHandler>
      <CalendarView
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
        eventAlarm={eventAlarm}
        handleDateClick={handleDateClick}
      />
    </ErrorHandler>
  );
};

const mapStateToProps = (state) => ({
  recentEvents: state.groupedEvents,
});

export default connect(mapStateToProps)(Calendar);
