/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useState, useCallback, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ImageViewer from 'react-simple-image-viewer';
import PropTypes from 'prop-types';
import isIphone from '../helpers/isIphone';

function TimelineImage({ imgUrl, animateImages, highlightedImages }) {
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [presentationImage, setPresentationImage] = useState(imgUrl[0]);
  const [error, setError] = useState(false);

  const onError = () => {
    setError(true);
  };

  const images = imgUrl;

  useEffect(() => {
    if (isIphone()) {
      return;
    }
    let imgShow = 0;
    let interval;
    if (animateImages) {
      interval = setInterval(() => {
        imgShow = (imgShow + 1) % imgUrl.length;
        setPresentationImage(imgUrl[imgShow]);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [animateImages, imgUrl]);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const handleKeyDown = (event, index) => {
    if (event.key === 'Enter' || event.key === ' ') {
      openImageViewer(index);
    }
  };

  const animationSlideshow = () => {
    if (!animateImages) return null;

    return (
      <img
        src={presentationImage}
        className="Image"
        role="presentation"
        tabIndex="0"
        width="100%"
        alt="Event"
      />
    );
  };

  return (
    <div className="Flex-image-container">
      <div className="Timeline-img-container">
        {animationSlideshow()}
        {images.map((src, index) =>
          error ? null : (
            <img
              data-testid="timeline-image"
              src={src}
              className={highlightedImages?.includes(src) ? 'Highlighted' : null}
              role="presentation"
              tabIndex="0"
              onError={onError}
              onKeyDown={(e) => handleKeyDown(e, index)}
              onClick={() => openImageViewer(index)}
              width={index === 0 && !animateImages ? '100%' : '20%'}
              key={`${src}-${uuidv4()}`}
              alt="Event"
            />
          ),
        )}
      </div>

      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
          closeOnClickOutside
          onClose={closeImageViewer}
          data-testid="timeline-image-viewer"
        />
      )}
    </div>
  );
}

TimelineImage.propTypes = {
  imgUrl: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TimelineImage;
