/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import CameraPlayer from './CameraPlayer';
import CameraPlayerImoulife from './CameraPlayerImoulife';

const CameraPlayerWrapper = ({ type, url, hideLoader, styleHeight }) => (
  <>
    {type === 'video' ? (
      <CameraPlayer url={url} hideLoader={hideLoader} height={styleHeight} />
    ) : (
      <CameraPlayerImoulife src={url} hideLoader={hideLoader} />
    )}
  </>
);

export default CameraPlayerWrapper;
