import backend from './backend';
import store from '../store/store';

const register = (name, phone, notifications, email, key, password) =>
  backend.post('/auth/signUp', {
    name,
    phone,
    notifications,
    email,
    key,
    password,
  });

const checkKey = (email, key) =>
  backend.post('/auth/signUp', {
    email,
    key,
  });

const updateProfile = (name, phone, language, email) =>
  backend.patch(`/user/${email}`, {
    name,
    phone,
    language,
    email,
  });

const updateSession = (session) => backend.get(`/auth/refresh/${session}`);

const resetPassword = (newPassword, currentPassword) => {
  const email = store.getState().auth.user.user.email;
  return backend.post('/auth/resetPassword', {
    email,
    newPassword,
    currentPassword,
  });
};

const forgotPassword = (email) => backend.post('/auth/forgotPassword', email);

const resetPasswordKey = (email, newPassword, key) =>
  backend.post('/auth/resetPassword', {
    email,
    key,
    newPassword,
  });

const login = (email, password) =>
  backend.post('/auth/signIn', {
    email,
    password,
  });

const logout = () => {
  localStorage.clear();
};

const authservice = {
  updateSession,
  checkKey,
  register,
  login,
  logout,
  updateProfile,
  resetPassword,
  forgotPassword,
  resetPasswordKey,
};

export default authservice;
