import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Popup from 'reactjs-popup';
import ToggleSwitch from '../elements/ToggleSwitch';
import Button from '../elements/Button';

const QuickInfoItem = ({ item, onToggle }) => {
  const [confirmPopupOpen, setConfirmPopupOpen] = useState(false);
  const { t } = useTranslation();

  const handleToggle = () => {
    setConfirmPopupOpen(!confirmPopupOpen);
  };

  const handleConfirmToggle = () => {
    onToggle(item.siteId, item.active, item.externalId, item.type);
    setConfirmPopupOpen(false);
  };

  return (
    <div className="Quick-info-container__items">
      <p>{item?.type}:</p>
      <p>{item.externalId}</p>
      <Popup
        trigger={
          <ToggleSwitch
            checked={item?.active || false}
            disabled={false}
            siteId={item.siteId}
            type={item.type}
            handleChange={handleToggle}
            label={`pause ${item.type.toLowerCase()}`}
            useDarkInactive={true}
          />
        }
        modal
        nested
        open={confirmPopupOpen}
        closeOnDocumentClick={false}
      >
        {() => (
          <div className="modal">
            <button className="close" type="button" onClick={() => setConfirmPopupOpen(false)}>
              &times;
            </button>
            <h4 className="header">{t('modal.confirmheader')}</h4>
            <div className="content">
              <p className="Text-grey Light-grey-text">{t('modal.proceed')}</p>
            </div>
            <div className="actions Row-container-right">
              <div className="Margin-rxs">
                <Button
                  className="Secondary-button"
                  text={t('buttons.cancel')}
                  size="small"
                  onClick={() => setConfirmPopupOpen(false)}
                />
              </div>
              <Button text={t('buttons.confirm')} onClick={handleConfirmToggle} size="small" />
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
};

export default QuickInfoItem;
