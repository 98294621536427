import React from 'react';
import axios from 'axios';
import { ulid } from 'ulidx';
import { ErrorBoundary } from 'react-error-boundary';
import config from '../config';

const backend = axios.create({ baseURL: config.apiUrl });

const submitFrontendLog = (data) => backend.post('/error', { ...data });

const ErrorPage = ({ u }) => (
  <div className="error-page">
    <div className="oops">Oops!</div>
    <div className="message">Something went wrong...{u}</div>
  </div>
);

export default function ErrorHandler({ children }) {
  const u = ulid();
  return (
    <ErrorBoundary
      FallbackComponent={() => ErrorPage({ u })}
      onError={(error, errorInfo) => {
        submitFrontendLog({
          error,
          errorInfo,
          u,
          time: new Date().toISOString(),
        });
      }}
    >
      {children}
    </ErrorBoundary>
  );
}
