import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { fetchFavourites } from '../actions/favourite';
import ErrorHandler from './ErrorHandler';
import HeaterPreviewFetch from './heaters/HeaterPreviewFetch';
import CameraFeedPreview from './camera/CameraFeedPreview';
import SensorPreview from './sensors/SensorPreview';
import SensorPreviewFetch from './concrete-sensor/SensorPreviewFetch';

const HomePreview = ({ favourites, currentSite }) => {
  const dispatch = useDispatch();

  const services = useMemo(() => {
    if (!currentSite?.services) return {};

    return currentSite.services.reduce((acc, service) => {
      const { type } = service;
      acc[type] = acc[type] ? [...acc[type], service] : [service];
      return acc;
    }, {});
  }, [currentSite]);

  useEffect(() => {
    dispatch(fetchFavourites());
  }, [currentSite, dispatch]);

  const filterFavourites = (allServices, allFavourites) => {
    if (!allFavourites?.length) return allServices;

    return Object.keys(allServices).reduce((filtered, key) => {
      const matches = allServices[key].filter((service) =>
        allFavourites.includes(service.externalId),
      );
      filtered[key] = matches.length ? matches : allServices[key];
      return filtered;
    }, {});
  };

  const filteredServices = filterFavourites(services, favourites);

  const previewClassName = `Home-preview${
    currentSite?.services?.length === 1 ? ' single-content' : ' multiple-content'
  }`;

  return (
    <ErrorHandler>
      <div className={previewClassName}>
        {Object.entries(filteredServices).map(([key, data]) => (
          <ServicePreview
            key={key}
            type={key}
            data={data}
            mode={favourites?.length && data.length === services[key].length ? 'all' : 'favourites'}
          />
        ))}
      </div>
    </ErrorHandler>
  );
};

const ServicePreview = ({ type, data, mode }) => {
  switch (type) {
    case 'env-sensor':
      return <SensorPreview data={data} mode={mode} />;
    case 'invisense':
    case 'screed':
      return <SensorPreviewFetch data={data} mode={mode} sensorType={type} />;
    case 'eze':
      return <HeaterPreviewFetch data={data} mode={mode} />;
    case 'video':
    case 'imoulife':
      return <CameraFeedPreview data={data} mode={mode} />;
    default:
      return null;
  }
};

export default HomePreview;
