import { combineReducers } from 'redux';
import auth from './auth';
import worksiteList from './worksite';
import siteId from './siteId';
import recentEvents from './timeline';
import usersList from './users';
import armingSchedule from './armingSchedule';
import alarmEvents from './alarmEvents';
import groupedEvents from './groupedEvents';
import siteInfo from './siteInfo';
import favouritesReducer from './favouritesReducer';

const allReducers = combineReducers({
  worksiteList,
  siteId,
  recentEvents,
  auth,
  usersList,
  armingSchedule,
  alarmEvents,
  groupedEvents,
  siteInfo,
  favouritesReducer,
});

export default allReducers;
