import React from 'react';
import ArrowClickLeft from './ArrowClickLeft';
import ArrowClickRight from './ArrowClickRight';

const ArrowClick = ({ leftClick, rightClick }) => (
  <span
    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '1rem' }}
  >
    <ArrowClickLeft leftClick={leftClick} />
    <ArrowClickRight rightClick={rightClick} />
  </span>
);

export default ArrowClick;
