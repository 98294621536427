/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as GreenShield } from '../images/green-shield.svg';
import { ReactComponent as RedShield } from '../images/red-shield.svg';
import privilegedRole from '../helpers/privilegedRole';

function Events({ item }) {
  const { t } = useTranslation();
  const unique = (value, index, self) => self.indexOf(value) === index;
  const translate = (value) => {
    const translated = t(`event.${value}`);
    return translated.substring(0, 6) === 'event.' ? value : translated;
  };

  const uniqueEvents = item
    .map(({ event }) => event)
    .filter((event) => event)
    .filter(unique);

  const events = uniqueEvents.map(translate).join(', ');

  const tokens = item.filter((obj) => obj?.verificationUrl !== undefined);

  let token;
  if (tokens.length > 0) {
    token = tokens[0].verificationUrl.split('verify/')[1];
  }

  const buildEvent = () => {
    if (events === 'ARM') {
      return <p className="Action-arm Sm-label-gr Bold-font">{events}</p>;
    }
    if (events === 'DISARM') {
      return <p className="Action-disarm Sm-label-gr Bold-font">{events}</p>;
    }
    if (events === 'ARMED') {
      return (
        <span className="Row-container-simple">
          <p className="Sm-label-gr Bold-font">{events}</p>
          <RedShield className="Timeline-icon" alt="arm-icon" fill="var(--primary)" />
        </span>
      );
    }
    if (events === 'DISARMED') {
      return (
        <span className="Row-container-simple">
          <p className="Sm-label-gr Bold-font">{events}</p>
          <GreenShield className="Timeline-icon" alt="disarm-icon" fill="var(--secondary)" />
        </span>
      );
    }

    if (token && privilegedRole(item[0].siteId)) {
      return (
        <Link to={`/verify/${token}`}>
          <p className="Sm-label-gr Linked-action">{events}</p>
        </Link>
      );
    }

    return <p className="Sm-label-gr">{events}</p>;
  };

  return <div>{buildEvent()}</div>;
}

export default Events;
