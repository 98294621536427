/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useContext } from 'react';
import ArmingButton from './ArmingButton';
import ArmingPanel from './ArmingPanel';
import { stateTypes } from '../helpers/siteStates';
import userContext from '../context/userContext';
import StateDescription from './StateDescription';
import NextScheduleEvent from './NextScheduleEvent';
import MixedStateAlert from './MixedStateAlert';
import SecurityTitle from './SecurityTitle';

export default function ArmingDashboard(props) {
  const installer = useContext(userContext);
  const currentWorksiteId = props.currentWorksiteId;
  const currentSite = props.currentSite;

  let currentState = stateTypes.UNDEFINED;
  let transition = [];
  let siteGateway = [];
  let inprogress = [];
  let armedCamera = [];
  let armedSiren = [];
  let armedSensor = [];
  let armedPir = [];
  let armedNotype = [];
  let disarmedCamera = [];
  let disarmedSiren = [];
  let disarmedSensor = [];
  let disarmedPir = [];
  let disarmedNotype = [];
  let armedFire = [];
  let disarmedFire = [];

  if (typeof currentSite?.statesCalculated !== 'undefined') {
    currentState = currentSite.statesCalculated.currentState;
    transition = currentSite.statesCalculated.transition;
    siteGateway = installer && currentSite.statesCalculated.gateway;
    inprogress = currentSite.statesCalculated.inprogress;
    armedCamera = currentSite.statesCalculated.armedCamera;
    armedSiren = currentSite.statesCalculated.armedSiren;
    armedSensor = currentSite.statesCalculated.armedSensor;
    armedPir = currentSite.statesCalculated.armedPir;
    armedNotype = installer && currentSite.statesCalculated.armedNotype;
    armedFire = currentSite.statesCalculated.armedFire;
    disarmedCamera = currentSite.statesCalculated.disarmedCamera;
    disarmedSiren = currentSite.statesCalculated.disarmedSiren;
    disarmedSensor = currentSite.statesCalculated.disarmedSensor;
    disarmedPir = currentSite.statesCalculated.disarmedPir;
    disarmedNotype = installer && currentSite.statesCalculated.disarmedNotype;
    disarmedFire = currentSite.statesCalculated.disarmedFire;
  }

  return (
    <>
      <SecurityTitle currentSite={currentSite} />
      <div className="Row-container Align-center Margin-bm">
        <NextScheduleEvent />
        {currentState === 'MIXED' && (
          <StateDescription currentState={currentState} inprogress={inprogress} />
        )}
      </div>
      <div className="Row-container-right">
        <MixedStateAlert currentState={currentState} />
      </div>
      {currentState !== 'UNDEFINED' && (
        <ArmingButton
          currentState={currentState}
          siteId={currentWorksiteId}
          inprogress={inprogress}
        />
      )}
      {currentState !== 'UNDEFINED' ? (
        <ArmingPanel
          states={{
            transition,
            armedCamera,
            armedSensor,
            armedPir,
            armedNotype,
            disarmedCamera,
            disarmedSensor,
            disarmedPir,
            armedSiren,
            disarmedSiren,
            disarmedNotype,
            siteGateway,
            armedFire,
            disarmedFire,
          }}
        />
      ) : null}
    </>
  );
}
