import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import useDataFetcher from '../../hooks/useDataFetcher';
import fetchPlotData from '../../services/fetchPlotData';
import SensorPreview from './SensorPreview';

const SensorPreviewFetch = ({ data, mode, sensorType }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { siteId } = useParams();

  const fetchSensorData = async (s) => {
    const sensor = await fetchPlotData(sensorType, s.externalId, s.name, {
      from: s.fromTime,
      to: s.toTime,
    });
    return sensor;
  };

  const { fetchedData, isError } = useDataFetcher(
    data,
    fetchSensorData,
    mode,
    currentIndex,
    siteId,
  );

  const handleLeftClick = () => {
    setCurrentIndex((oldIndex) => (oldIndex - 1 + data.length) % data.length);
  };

  const handleRightClick = () => {
    setCurrentIndex((oldIndex) => (oldIndex + 1) % data.length);
  };

  if (fetchedData.length === 0) {
    return null;
  }

  return (
    <>
      {fetchedData?.map((sensor) => (
        <SensorPreview
          key={sensor.meta?.externalId}
          sensor={sensor}
          mode={mode}
          fetchedData={fetchedData}
          sensorType={sensorType}
          handleLeftClick={handleLeftClick}
          handleRightClick={handleRightClick}
        />
      ))}

      {isError && <p>{isError}</p>}
    </>
  );
};

export default SensorPreviewFetch;
