import store from '../store/store';

export function authHeader() {
  const token = store.getState().auth?.token;
  return { Authorization: `Bearer ${token}` };
}

export function getSessionToken() {
  const session = localStorage.getItem('session');

  if (session) {
    return session;
  }

  return null;
}
