/* eslint-disable no-unused-vars */
import {
  ADD_EVENTS,
  ADD_ALARM_EVENTS,
  CLEAR_ALARM_EVENTS,
  CLEAR_EVENTS,
  CLEAR_GROUPED_EVENTS,
  ADD_EVENTS_TO_GROUPS,
} from './types';

import timelineservice from '../services/timelineservice';
import calendarservice from '../services/calendarservice';

export const getEvents = () => (dispatch) => {
  timelineservice
    .getEvents()
    .then((response) => {
      dispatch({
        type: ADD_EVENTS,
        payload: response.data,
      });
      dispatch({
        type: ADD_EVENTS_TO_GROUPS,
        payload: response.data?.events,
      });
    })
    .catch(() => {});
};

export const getWeeklyEvents = (from, to) => (dispatch) => {
  calendarservice
    .getWeeklyEvents(from, to)
    .then((response) => {
      dispatch({
        type: ADD_EVENTS,
        payload: response.data,
      });
      dispatch({
        type: ADD_EVENTS_TO_GROUPS,
        payload: response.data?.events,
      });
    })
    .catch(() => {});
};

export const getAlarmEvents = (magicToken, callback) => (dispatch) => {
  timelineservice
    .getAlarmEvents(magicToken)
    .then((response) => {
      dispatch({
        type: ADD_ALARM_EVENTS,
        payload: response.data,
      });
    })
    .catch((error) => {
      callback(error);
      return error;
    });
};

export const clearAlarmEvents = () => (dispatch) => {
  dispatch({
    type: CLEAR_ALARM_EVENTS,
  });
};

export const clearEvents = () => (dispatch) => {
  dispatch({
    type: CLEAR_EVENTS,
  });
  dispatch({
    type: CLEAR_GROUPED_EVENTS,
  });
};
