import backend from './backend';
import store from '../store/store';

const getWorksites = () => backend.get('/site');
const createWorksite = (data) => backend.post('/site', data);
const updateWorksite = (data, siteId) => backend.patch(`/site/${siteId}`, data);
const createWorksiteExternal = (data, siteId) => backend.post(`/site/${siteId}/external`, data);
const updateWorksiteVideo = (data, siteId) => backend.patch(`/site/${siteId}/external`, data);
const updatePTZ = (data, siteId, id) => backend.patch(`/site/${siteId}/external/video/${id}`, data);
const updateImouPTZ = (data, siteId, id) =>
  backend.patch(`/site/${siteId}/external/imoulife/${id}`, data);
const updateExternalService = (data, siteId, type, externalId) =>
  backend.patch(`/site/${siteId}/external/${type}/${externalId}`, data);
const updateAllLights = (siteId, data) => backend.patch(`/site/${siteId}/external/light/`, data);
const updateZone = (siteId, areaId, data) => backend.patch(`/site/${siteId}/area/${areaId}`, data);
const deleteWorksiteExternal = (data, siteId) =>
  backend.delete(`/site/${siteId}/external`, { data });
const deleteWorksite = (siteId) => backend.delete(`/site/${siteId}`);
const armSite = (siteId, state) => backend.put(`/site/${siteId}/arm`, state);
const armDevice = (areaId, state) => {
  const siteId = store.getState().siteId;
  return backend.put(`/site/${siteId}/arm/${areaId}`, state);
};
const deleteDevice = (areaId) => {
  const siteId = store.getState().siteId;
  return backend.delete(`/site/${siteId}/area/${areaId}`);
};
const takeSnapshot = (siteId, areaId) => backend.post(`/site/${siteId}/snapshot/${areaId}`);

const getService = (data) => backend.get(`/site/external/${data.service}`);

const updateExternal = (siteId, data) => backend.patch(`/site/${siteId}/external`, data);

const resetTimeline = (patchData, siteId) =>
  backend.patch(`/site/${siteId}/resetTimeline`, patchData);

const getUploadUrl = (siteId, data) =>
  backend.post(`/site/${siteId}/external/get-upload-url`, data);

const worksiteservice = {
  armSite,
  armDevice,
  deleteDevice,
  getWorksites,
  createWorksite,
  updateWorksite,
  createWorksiteExternal,
  updatePTZ,
  updateImouPTZ,
  updateWorksiteVideo,
  deleteWorksite,
  deleteWorksiteExternal,
  updateZone,
  updateExternalService,
  updateAllLights,
  takeSnapshot,
  getService,
  updateExternal,
  resetTimeline,
  getUploadUrl,
};

export default worksiteservice;
