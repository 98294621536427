/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import TimelineItem from './TimelineItem';
import TimelineGroupPreview from './TimelineGroupPreview';
import Arrow from '../images/arrow-down.svg';
import ScrollButton from './ScrollButton';
import AnimatedTransitionVertical from './AnimatedTransitionVertical';
import { groupArmingEvents } from '../helpers/groupEvents';
import ErrorHandler from './ErrorHandler';
import useGetEvents from '../hooks/useGetEvents';

function Timeline(props) {
  const [isVisible, setIsVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(50);
  const { t } = useTranslation();
  const fetchEvents = useGetEvents();
  const armingStates = ['ARM', 'DISARM', 'ARMED', 'DISARMED', 'AUTOMATIC TEST'];
  const currentWorksiteId = useSelector((state) => state.siteId);

  const recentEvents = useSelector((state) => state.groupedEvents);
  const currentItems = groupArmingEvents(recentEvents);

  const memoizedWorksiteId = useMemo(() => currentWorksiteId, [currentWorksiteId]);

  const observer = useRef(
    new IntersectionObserver(
      (entries) => {
        const first = entries[0];
        if (first.isIntersecting && currentItems !== null) {
          const totalPages = Math.ceil(currentItems.length / itemsPerPage);
          fetchEvents();

          setTimeout(() => {
            if (currentPage <= totalPages) {
              setCurrentPage((prev) => prev + 1);
            }
          }, 300);
        }
      },
      { threshold: 1 },
    ),
  );
  const [element, setElement] = useState(null);

  useEffect(() => {
    const currentElement = element;
    const currentObserver = observer.current;
    if (currentElement) {
      currentObserver.observe(currentElement);
    }
    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [element]);

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  // Get events when componentDidMount and componentDidUpdate
  useEffect(() => {
    scrollToTop();
    setCurrentPage(1);
    if (typeof memoizedWorksiteId === 'string') {
      setIsVisible(false);
      fetchEvents();
    }
    setTimeout(() => {
      setIsVisible(true);
    }, 300);
  }, [memoizedWorksiteId]);

  if (currentItems.length === 0) return null;

  return (
    <div data-testid="timeline">
      <ErrorHandler>
        <h2 className="Padding-tl">{t('home.timeline')}</h2>
        <AnimatedTransitionVertical isVisible={isVisible}>
          <div className="Timeline-container">
            {currentItems.map((item, index) =>
              armingStates.includes(item[0].event) ? (
                <TimelineGroupPreview item={item} key={`group-${index}-${item[0].uuid}`} />
              ) : (
                <TimelineItem calendarDate={props.calendarDate} item={item} key={item[0].uuid} />
              ),
            )}
            <ScrollButton />
            {currentItems.length &&
              currentPage <= Math.ceil(currentItems.length / itemsPerPage) && (
                <img ref={setElement} src={Arrow} alt="load more" className="Display-block" />
              )}
          </div>
        </AnimatedTransitionVertical>
      </ErrorHandler>
    </div>
  );
}

export default Timeline;
