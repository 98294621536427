/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ReactComponent as ButtonElement } from '../images/ArmingButtonElement.svg';
import { armWorksite } from '../actions/sites';
import Popup from './Popup';

function FallbackButton(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const buttonClickedFallback = () => {
    const req = props.currentState !== props.stateTypes.ARMED ? { arm: false } : { arm: true };
    dispatch(armWorksite(props.currentWorksiteId, req, props.armingResult));
  };

  if (props.currentState === props.stateTypes.MIXED) {
    let fallbackClass;
    let modalContent;
    switch (props.currentState) {
      case props.stateTypes.MIXED:
      case props.stateTypes.DISARMED:
        fallbackClass = 'Arming-fallback-button-armed';
        modalContent = t('modal.confirmdisarm');
        break;
      case props.stateTypes.ARMED:
        fallbackClass = 'Arming-fallback-button';
        modalContent = t('modal.confirmarm');
        break;
      case props.stateTypes.UNDEFINED:
      default:
        fallbackClass = 'Undefined-button';
        break;
    }

    return (
      <Popup
        trigger={
          <button type="button" className={fallbackClass}>
            <ButtonElement alt="button-element" />
          </button>
        }
        action={buttonClickedFallback}
        header={t('modal.confirmheader')}
        content={modalContent}
        btnCancel={t('buttons.nocancel')}
        btnAction={t('buttons.confirm')}
      />
    );
  }
  return null;
}

export default FallbackButton;
