import {
  WORKSITE_LIST_FETCHING,
  WORKSITE_LIST,
  UPDATE_WORKSITES,
  CLEAR_WORKSITE_LIST,
  PARTIAL_UPDATE_WORKSITE,
  PARTIAL_UPDATE_WORKSITE_STATE,
} from '../actions/types';
import normalizingData from '../helpers/normalizing';

import { calcStates } from '../helpers/siteStates';

const initialState = { isLoaded: false, worksites: [] };

export default function worksiteList(state = initialState, action) {
  const { type, payload } = action;
  let installer = false;

  switch (type) {
    case WORKSITE_LIST_FETCHING:
      return {
        ...state,
        isLoaded: false,
      };
    case WORKSITE_LIST:
    case UPDATE_WORKSITES:
      if (payload.worksites && Array.isArray(payload.worksites)) {
        const worksites = [...payload.worksites];
        installer = payload.installer;
        for (let i = 0; i < worksites.length; i += 1) {
          worksites[i].statesCalculated = calcStates(worksites[i].state?.states, installer ? 1 : 0);
        }
        const worksiteObjects = normalizingData(worksites, 'siteId', 'siteName');

        return {
          ...state,
          isLoaded: true,
          worksites,
          worksiteObjects,
        };
      }
      return state;
    case PARTIAL_UPDATE_WORKSITE: {
      const s = { ...state };
      if (typeof payload.siteId === 'string') {
        s.worksites = s.worksites.map((site) => {
          let siteInfo = { ...site };
          if (siteInfo.siteId === payload.siteId) {
            siteInfo = { ...site, ...payload };
          }
          return siteInfo;
        });
      }
      return {
        ...s,
      };
    }
    case PARTIAL_UPDATE_WORKSITE_STATE: {
      const s = { ...state };
      if (typeof payload.siteId === 'string') {
        s.worksites = s.worksites.map((site) => {
          const siteInfo = { ...site };
          if (siteInfo.siteId === payload.siteId) {
            if (siteInfo.state?.siteId !== payload.siteId) return siteInfo;

            const wsState = siteInfo.state;
            if (payload.areaId === '*') {
              Object.keys(siteInfo.state.states).forEach((a) => {
                wsState.states[a].updated = payload.updated;
                wsState.states[a].state = payload.state || payload.event;
              });
            } else {
              if (!wsState.states[payload.areaId]) wsState.states[payload.areaId] = {};
              wsState.states[payload.areaId].areaId = payload.areaId;
              wsState.states[payload.areaId].updated = payload.updated;
              wsState.states[payload.areaId].state = payload.state || payload.event;
            }
            siteInfo.statesCalculated = calcStates(wsState.states, installer ? 1 : 0);
          }
          return siteInfo;
        });
      }
      return {
        ...s,
      };
    }
    case CLEAR_WORKSITE_LIST:
      return {
        ...state,
        isLoaded: false,
        worksites: [],
      };
    default:
      return state;
  }
}
