import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Battery0 } from '../images/battery-0.svg';
import { ReactComponent as Battery20 } from '../images/battery-10.svg';
import { ReactComponent as Battery40 } from '../images/battery-30.svg';
import { ReactComponent as Battery60 } from '../images/battery-50.svg';
import { ReactComponent as Battery80 } from '../images/battery-80.svg';
import { ReactComponent as Battery100 } from '../images/battery-100.svg';
import colors from '../helpers/colors.json';

function BatteryLevel({ battery }) {
  const { t } = useTranslation();
  if (!battery) {
    return null;
  }
  const batteryNum = battery.match(/(\d+)/)[0];

  const defineIcon = () => {
    if (batteryNum === 0) {
      return <Battery0 fill="var(--primary)" />;
    }
    if (batteryNum <= 20) {
      return <Battery20 fill={colors.darkerText} />;
    }
    if (batteryNum <= 40) {
      return <Battery40 fill={colors.darkerText} />;
    }
    if (batteryNum <= 60) {
      return <Battery60 fill="var(--secondary)" />;
    }
    if (batteryNum <= 80) {
      return <Battery80 fill="var(--secondary)" />;
    }
    if (batteryNum <= 100) {
      return <Battery100 fill="var(--secondary)" />;
    }
    return false;
  };

  return (
    <>
      <p className="Sm-label-gr">{t('battery')}</p>
      <span className="Row-container-simple">
        {defineIcon()}
        <p className="Sm-label-gr Margin-ls Bold-font">{battery}</p>
      </span>
    </>
  );
}

export default BatteryLevel;
