/* eslint-disable react/button-has-type */
import React from 'react';
import Popup from 'reactjs-popup';
import Button from '../elements/Button';

export default (props) => (
  <Popup trigger={props.trigger} modal nested>
    {(close) => (
      <div className="modal" data-testid="modal">
        <button className="close" onClick={close}>
          &times;
        </button>
        <h4 className="header">{props.header}</h4>
        <div className="content">
          <p className="Text-grey Light-grey-text">{props.content}</p>
        </div>
        <div className="actions Row-container-right">
          {props.btnCancel && (
            <div className="Margin-rxs">
              <Button
                className="Secondary-button"
                text={props.btnCancel}
                onClick={() => {
                  close();
                }}
                size="small"
              />
            </div>
          )}
          {props.btnAction && (
            <Button
              testid="Confirm-button"
              text={props.btnAction}
              onClick={() => {
                props.action();
                close();
              }}
              size="small"
            />
          )}
        </div>
      </div>
    )}
  </Popup>
);
