import React from 'react';
import Plot from 'react-plotly.js';
import colors from '../../helpers/colors.json';

// eslint-disable-next-line react/function-component-definition
const SensorChart = ({ plotData }) => {
  const { name } = plotData.meta;
  const temperatureLegend = plotData.values.some((item) => item.temperature !== undefined);
  const humidityLegend = plotData.values.some((item) => item.humidity !== undefined);
  const dateArray = plotData.values.map((item) => new Date(item.time * 1000));

  const rootStyle = getComputedStyle(document.documentElement);

  const primaryColor = rootStyle.getPropertyValue('--primary').trim();
  const secondaryColor = rootStyle.getPropertyValue('--secondary').trim();

  const trace1 = {
    x: dateArray,
    y: plotData.values.map((item) => item.temperature),
    mode: 'lines',
    type: 'scatter',
    name: 'Temperature',
    line: {
      color: primaryColor,
    },
    visible: temperatureLegend,
  };
  const trace2 = {
    x: dateArray,
    y: plotData.values.map((item) => item.humidity),
    mode: 'lines',
    type: 'scatter',
    yaxis: 'y2',
    name: 'Humidity',
    line: {
      color: secondaryColor,
    },
    visible: humidityLegend,
  };
  const data = [trace1, trace2];

  return (
    <div className="Main-content">
      <div style={{ textAlign: 'center' }}>
        <Plot
          data={data}
          style={{ height: '450px', minWidth: '280px' }}
          layout={{
            autosize: true,
            plot_bgcolor: 'rgba(0,0,0,0)',
            paper_bgcolor: colors.lightText,
            title: {
              text: name,
              x: '0.1',
            },
            hovermode: 'x',
            font: {
              color: '#16205B',
            },
            xaxis: {
              gridcolor: colors.mediumText,
              type: 'date',
              tickformat: '%b %d, %Y',
              hoverformat: '%b %d, %Y %H:%M:%S',
              rangeslider: {},
            },
            yaxis: {
              title: 'Temperature',
              visible: temperatureLegend,
            },
            yaxis2: {
              gridcolor: colors.mediumText,
              title: 'Humidity',
              overlaying: 'y',
              side: 'right',
              visible: humidityLegend,
            },
            legend: {
              orientation: 'h',
              yanchor: 'bottom',
              y: 1.02,
              xanchor: 'right',
              x: 1,
            },
            showlegend: temperatureLegend || humidityLegend,
          }}
          useResizeHandler={true}
          config={{ responsive: true }}
        />
      </div>
    </div>
  );
};

export default SensorChart;
