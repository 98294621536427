import config from './config';

const publicVapidKey = config.publicVapidKey;

// Check if service workers are supported
function isPushNotificationSupported() {
  return 'serviceWorker' in navigator && 'PushManager' in window;
}

function registerServiceWorker() {
  return navigator.serviceWorker.register('/sw.js');
}

async function askUserPermission() {
  await Notification.requestPermission();
}

// Copied from the web-push documentation
const urlBase64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    // .replace(/\-/g, "+")
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; i += 1) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

async function createNotificationSubscription() {
  // wait for service worker installation to be ready
  const serviceWorker = await navigator.serviceWorker.ready;
  // subscribe and return the subscription
  const subscription = await serviceWorker.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
  });
  return subscription;
}

function getUserSubscription() {
  // wait for service worker installation to be ready, and then
  return navigator.serviceWorker.ready
    .then((serviceWorker) => serviceWorker.pushManager.getSubscription())
    .then((pushSubscription) => pushSubscription);
}

export {
  isPushNotificationSupported,
  askUserPermission,
  registerServiceWorker,
  createNotificationSubscription,
  getUserSubscription,
};
