/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TimelineItem from './TimelineItem';
import AnimatedTransitionVertical from './AnimatedTransitionVertical';
import Button from '../elements/Button';

function TimelineGroupPreview(props) {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  const showItems = () => {
    setShow(!show);
  };

  if (props.item?.length === 1) {
    return <TimelineItem item={props.item} key={props.index} />;
  }
  return (
    <div className="Timeline-group-preview">
      <AnimatedTransitionVertical isVisible={!show}>
        {props.item?.length > 1 && <TimelineItem item={[props.item[0]]} key={props.item[0]} />}
        {props.item?.length > 1 && (
          <div className="Timeline-preview">
            <div className="Row-container-right Padding-rm Margin-bm">
              <Button
                className="Secondary-button"
                size="small"
                onClick={showItems}
                text={t('buttons.showall')}
              />
            </div>
          </div>
        )}
      </AnimatedTransitionVertical>

      <AnimatedTransitionVertical isVisible={show}>
        {show && (
          <>
            {props.item.map((el) => (
              <TimelineItem item={[el]} key={el} />
            ))}
          </>
        )}
      </AnimatedTransitionVertical>
    </div>
  );
}

export default TimelineGroupPreview;
