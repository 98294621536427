/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable prefer-destructuring */
import { PushNotifications, Token } from '@capacitor/push-notifications';

import { Capacitor } from '@capacitor/core';
import backend from '../services/backend';

const registerFCMPushNotifications = () => {
  if (!Capacitor.isNativePlatform()) {
    // console.log('On web, ignore NativePush.');
    return;
  }
  // ----------------- Push Notifications -----------------

  // Request permission to use push notifications
  // iOS will prompt user and return if they granted permission or not
  // Android will just grant without prompting
  PushNotifications.requestPermissions().then((result) => {
    if (result.receive === 'granted') {
      // Register with Apple / Google to receive push via APNS/FCM
      PushNotifications.register();
    } else {
      // Show some error
    }
  });

  // On success, we should be able to receive notifications
  PushNotifications.addListener('registration', (token: Token) => {
    // alert('Push registration success, token: ' + token.value);
    // console.log('Push registration success, token: ' + token.value);
    backend
      .post('/user/subscriptions/mobilePush', {
        registrationToken: token.value,
      })
      .then((_res) => {
        // alert('Res: ' + res.headers);
        // console.log(res);
      })
      .catch((_err) => {
        // alert('err: ' + res);
      });
    // alert('Register with backend: ' + token.value + "\n");
  });

  // Some issue with our setup and push will not work
  PushNotifications.addListener('registrationError', (_error: any) => {
    // alert('Error on registration: ' + JSON.stringify(error));
  });

  // Show us the notification payload if the app is open on our device
  PushNotifications.addListener(
    'pushNotificationReceived',
    (_notification: PushNotificationSchema) => {
      // alert('Push received: ' + JSON.stringify(notification));
    },
  );

  // Method called when tapping on a notification
  PushNotifications.addListener(
    'pushNotificationActionPerformed',
    (_notification: ActionPerformed) => {
      // alert('Push action performed: ' + JSON.stringify(notification));
    },
  );

  // ----------------- End of Push Notifications -----------------
};

export default registerFCMPushNotifications;
