import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  SET_ACCESS_TOKEN,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_ACCESS_TOKEN,
  TOGGLE_INSTALLER,
} from '../actions/types';
import normalizingData from '../helpers/normalizing';

const user = JSON.parse(localStorage.getItem('user'));

const initialState = user ? { isLoggedIn: true, user } : { isLoggedIn: false, user: null };

export default function auth(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CLEAR_ACCESS_TOKEN:
      return {
        ...state,
        isLoggedIn: false,
        token: '',
      };
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        isLoggedIn: true,
        token: payload.token,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case LOGIN_SUCCESS: {
      let normalizedRoles;
      if (payload.user?.roles)
        normalizedRoles = normalizingData(payload.user.roles, 'siteId', 'role');

      return {
        ...state,
        normalizedRoles,
        isLoggedIn: true,
        user: payload.user,
        initialInstallerStatus: payload.user.installer,
      };
    }
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case TOGGLE_INSTALLER:
      return {
        ...state,
        user: {
          ...state.user,
          installer: !state.user.installer,
        },
      };
    default:
      return state;
  }
}
