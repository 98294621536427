import store from '../../../store/store';
import { PARTIAL_UPDATE_WORKSITE, PARTIAL_UPDATE_WORKSITE_STATE } from '../../../actions/types';

const setupEvents = (socket) => {
  socket.on('site', (data) => {
    if (typeof data !== 'object') return;

    const payload = { ...data };

    store.dispatch({
      type: PARTIAL_UPDATE_WORKSITE,
      payload,
    });
  });

  socket.on('state', (data) => {
    if (typeof data !== 'object') return;

    const payload = { ...data };
    store.dispatch({
      type: PARTIAL_UPDATE_WORKSITE_STATE,
      payload,
    });
  });

  // socket.on("chat", (data) => {
  //   console.log(`Chat event: ${JSON.stringify(data)}`);
  // });
};
export default setupEvents;
