export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const CLEAR_ACCESS_TOKEN = 'CLEAR_ACCESS_TOKEN';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const PARTIAL_UPDATE_USER = 'PARTIAL_UPDATE_USER';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

export const TOGGLE_INSTALLER = 'TOGGLE_INSTALLER';

export const SETTINGS_SUCCESS = 'SETTINGS_SUCCESS';
export const SETTINGS_FAIL = 'SETTINGS_FAIL';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD_KEY = 'RESET_PASSWORD_KEY';

export const WORKSITE_SUCCES = 'WORKSITE_SUCCES';
export const WORKSITE_UPDATE = 'WORKSITE_UPDATE';
export const UPDATE_SIA_ZONE = 'SET_SIA_ZONE';
export const WORKSITE_UPDATE_EXTERNAL = 'WORKSITE_UPDATE_EXTERNAL';
export const WORKSITE_UPDATE_VIDEO = 'WORKSITE_UPDATE_VIDEO';
export const DELETE_WORKSITE_EXTERNAL = 'DELETE_WORKSITE_EXTERNAL';
export const DELETE_WORKSITE = 'DELETE_WORKSITE';
export const PARTIAL_UPDATE_WORKSITE = 'PARTIAL_UPDATE_WORKSITE';
export const PARTIAL_UPDATE_WORKSITE_STATE = 'PARTIAL_UPDATE_WORKSITE_STATE';

export const WORKSITE_LIST_FETCHING = 'WORKSITE_FETCHING';
export const WORKSITE_LIST = 'WORKSITE_LIST';
export const CLEAR_WORKSITE_LIST = 'CLEAR_WORKSITE_LIST';
export const UPDATE_WORKSITES = 'UPDATE_WORKSITES';

export const SET_SITE_ID = 'SET_SITE_ID';
export const CLEAR_SITE_ID = 'CLEAR_SITE_ID';

export const SET_SITE_INFO = 'SET_SITE_INFO';
export const CLEAR_SITE_INFO = 'CLEAR_SITE_INFO';

export const CREATE_USER = 'CREATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_ROLE = 'DELETE_USER_ROLE';
export const UPDATE_USER_ROLE = 'UPDATE_USER_ROLE';
export const USERS_LIST = 'USERS_LIST';
export const CURRENT_USERS_LIST = 'CURRENT_USERS_LIST';
export const CLEAR_USERS = 'CLEAR_USERS';

export const ADD_EVENTS = 'ADD_EVENTS';
export const ADD_GROUPED_EVENTS = 'ADD_GROUPED_EVENTS';
export const ADD_EVENTS_TO_GROUPS = 'ADD_EVENTS_TO_GROUPS';
export const ADD_ALARM_EVENTS = 'ADD_ALARM_EVENTS';
export const CLEAR_GROUPED_EVENTS = 'CLEAR_GROUPED_EVENTS';
export const CLEAR_ALARM_EVENTS = 'CLEAR_ALARM_EVENTS';
export const CLEAR_EVENTS = 'CLEAR_EVENTS';

export const GET_SCHEDULE = 'GET_SCHEDULE';
export const PUT_SCHEDULE = 'PUT_SCHEDULE';
export const CHANGE_SCHEDULE = 'CHANGE_SCHEDULE';
export const DELETE_SCHEDULE = 'DELETE_SHEDULE';
export const CLEAR_SCHEDULE = 'CLEAR_SCHEDULE';

export const SETTING_SCHEDULE = 'SETTING_SCHEDULE';
export const EDIT_SETTING_SCHEDULE = 'EDIT_SETTING_SCHEDULE';
export const INITIAL_SETTING_SCHEDULE = 'INITIAL_SETTING_SCHEDULE';
export const CLEAR_SETTING_SCHEDULE = 'CLEAR_SETTING_SCHEDULE';

export const TEST_WORKSITE = 'TEST_WORKSITE';
export const TEST_CHANGE_WORKSITE = 'TEST_CHANGE_WORKSITE';
export const TEST_DELETE_WORKSITE = 'TEST_DELETE_WORKSITE';

export const SET_FAVOURITES = 'SET_FAVOURITES';
